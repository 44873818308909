#root #infoCentre-portal-body .ui-mobile .referral-program {
    font-size: 0.33rem;
    scroll-behavior: smooth;
}
#root #infoCentre-portal-body .ui-mobile .referral-program div.anchor {
    position: relative;
    display: block;
    top: -2.5rem;
    visibility: hidden;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .navbar {
    position: fixed;
    max-width: 100%;
    z-index: 2;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav {
    background-color: #0066d2;
    display: flex;
    height: 1.25rem;
    overflow: scroll;
    padding-left: 0.3rem;
    align-items: center;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav .navItem {
    white-space: nowrap;
    text-transform: uppercase;
    height: 0.75rem;
    margin: auto 0.6rem;
    margin-top: 0.25rem;
    border-bottom: solid 0.08rem transparent;
    border-top: solid 0.08rem transparent;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav .navItem:last-child {
    padding: 0 0.05rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav .active .navItem {
    border-bottom-color: #fdb813;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav .active .navItem > div {
    color: #fdb813 !important;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .nav .navItem div {
    display: flex;
    align-items: center;
    transition: 0.3s;
    font-size: 0.36rem;
    color: #fff;
    font-family: arial;
    text-align: "center";
}
#root #infoCentre-portal-body .ui-mobile .referral-program .space {
    height: 1.25rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content .content {
    margin: 0.5rem 1rem;
    word-wrap: break-word;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content .content .title span {
    color: #fdb813;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content .content .desc p {
    padding: 0.2rem 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content .content .title {
    padding-bottom: 0.2rem;
    font-weight: 600;
    font-size: 0.5rem;
    color: #000;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content.top-content .content {
    margin: 0.5rem 1rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-content.top-content .content .title {
    padding-bottom: 0.2rem;
    font-weight: 700;
    font-size: 0.5rem;
    color: #003c72;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .product-list {
    display: flex;
    margin-top: 0.36rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .product-list div {
    font-size: 0.24rem;
    margin: 0 0.12rem;
    text-align: center;
    line-height: 1;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .product-list div > img {
    width: 100%;
    height: 1.1rem;
    margin-bottom: 0.15rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .product-list div:first-child {
    margin-left: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse .order-list,
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse .unorder-list {
    display: flex;
    margin: 0.1rem 0;
    font-size: 0.3rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse .order-list div:first-child {
    width: 6%;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse .order-list div:nth-of-type(2) {
    width: 95%;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../../static/html/banner/rp-banner-share.jpg);
    background-size: cover;
    height: 4rem;
    width: auto;
    margin: 25% 4%;
    margin-top: 10%;
    text-align: center;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content .title {
    line-height: 1;
    padding: 0.2rem 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content .desc {
    font-size: 0.26rem;
    color: #0066d2;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content .share-container {
    display: flex;
    justify-content: center;
    margin: 0.1rem;
    margin-top: 0.5rem;
    color: #000;
    font-weight: 600;
    font-size: 0.3rem;
    background-color: #fff;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content .share-container .code-box {
    width: 50%;
    padding: 0.1rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .rp-refer-friend .content .share-container .share-button {
    background-color: #fdb813;
    -webkit-border-radius: 0.05rem;
    border-radius: 0.05rem;
    width: 40%;
    padding: 0.1rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
#root #infoCentre-portal-body .ui-mobile .referral-program #root #infoCentre-portal-body .ui-mobile .referral-program .panel-body {
    padding: 15px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-body:before,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-body:after {
    content: " ";
    display: table;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-body:after {
    clear: both;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-heading {
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.4rem;
    color: inherit;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title > a,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title > small,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title > .small,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title > small > a,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-title > .small > a {
    color: #000;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .list-group,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .list-group {
    margin-bottom: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .list-group .list-group-item,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .list-group:first-child .list-group-item:first-child,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .list-group:last-child .list-group-item:last-child,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > #root #infoCentre-portal-body .ui-mobile .referral-program .panel-heading + .panel-collapse > .list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .list-group + .panel-footer {
    border-top-width: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table-responsive > .table,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .table {
    margin-bottom: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table caption,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table-responsive > .table caption,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-collapse > .table caption {
    padding-left: 15px;
    padding-right: 15px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-body + .table,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .panel-body + .table-responsive,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table + .panel-body,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel > .table-responsive + .panel-body {
    border-top: 1px solid #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group {
    margin-bottom: 0.2rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel + .panel {
    margin-top: 5px;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel-heading {
    border-bottom: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel-heading + .panel-collapse > #root #infoCentre-portal-body .ui-mobile .referral-program .panel-body,
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 1px solid #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel-footer {
    border-top: 0;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-default {
    border-color: #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-default > .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-default > .panel-heading .badge {
    color: #f5f5f5;
    background-color: #333;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ddd;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-primary {
    border-color: #337ab7;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-primary > .panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-primary > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #337ab7;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-primary > .panel-heading .badge {
    color: #337ab7;
    background-color: #fff;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-primary > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #337ab7;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-info {
    border-color: #bce8f1;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-info > .panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-info > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #bce8f1;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-info > .panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .panel-info > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #bce8f1;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .fade {
    -webkit-transition: opacity 0.1s linear;
    -o-transition: opacity 0.1s linear;
    transition: opacity 0.1s linear;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse {
    display: none;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse.in {
    display: block;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse p,
#root #infoCentre-portal-body .ui-mobile .referral-program .collapse.in p {
    margin: 0.1rem 0;
    font-size: 0.3rem;
}
#root #infoCentre-portal-body .ui-mobile .referral-program .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

#root #infoCentre-portal-body .ui-mobile .mobile-tnc .info_tab {
    background-color: #fff;
    border: 1px solid #cbb698;
    padding: 0.5rem;
    box-shadow: 0 0 6px 0 red;
    margin-bottom: 0.5rem;
    color: #6a718e;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .contact_inner_title {
    font-size: 0.5rem;
    font-weight: 700;
    color: #6a718e;
    margin: 0 0 10px;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .text-center {
    text-align: center !important;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .blog_content {
    font-size: 0.3rem;
    margin-top: 10px;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .blog_content button {
    font-size: 0.35rem;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .tncaccordion .card {
    border: none;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .tncaccordion .card .tncbtn.collapsed {
    background: #fff;
    border: 1px solid #cba98c;
    color: #6a718e;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .tncaccordion .card .tncbtn {
    background: linear-gradient(to bottom, #eacdad 0, #c3aa8a 99%);
    width: 100%;
    text-align: left;
    height: 1.1rem;
    border-radius: 0.3rem;
    color: #fff;
    margin-bottom: 0.2rem;
    font-weight: 500;
    padding-left: 0.3rem;
    border: none;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .card-body {
    flex: 1 1 auto;
    padding: 0 0.2rem;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .tncaccordion .card ul {
    padding-left: 0;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn:focus,
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn.focus,
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn:active:focus,
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn:active.focus,
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn.active:focus,
#root #infoCentre-portal-body .ui-mobile .mobile-tnc .btn.active.focus {
    outline: 0 auto -webkit-focus-ring-color;
}
#root #infoCentre-portal-body .ui-mobile .mobile-tnc li {
    list-style: none;
    margin: 0.25rem 0;
    line-height: .45rem;
}

#root #infoCentre-portal-body .ui-mobile .mobile-tnc .collapse{
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out;
}

#root #infoCentre-portal-body .ui-mobile .mobile-tnc .collapse.show{max-height: 1000px;}  