html {
  font-size: 9.25926vw;
  color: #6d6d6d;
  background: #ffffff;
  height: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

html body {
  overflow-x: hidden;
  font-size: 16px;
  letter-spacing: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

html * {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    box-sizing: border-box;
    font-family: sans-serif;
}

html body a {
  text-decoration: none;
}

*::-webkit-scrollbar {
  width: 1px;
  height: 0;
}

html body a, html body a:visited {
  text-decoration: none;
}

/***********Global****************/
#root #infoCentre-portal-body .ui-mobile .ios9Fix {
  overflow-x: hidden;
  position: relative;
}

#root #infoCentre-portal-body .ui-mobile .m-main-content-lite {
  min-height: 100vh;
}

#root #infoCentre-portal-body .ui-mobile .m-main-content {
  display: grid;
  grid-template-rows: 1.1rem 1fr 1.6rem;
  min-height: 100vh;
}

#root #infoCentre-portal-body .ui-mobile .m-main-content.home-no-login {
  display: grid;
  grid-template-rows: 1.3rem 1fr;
  min-height: 100vh;
}

#root #infoCentre-portal-body .ui-mobile .m-main-content.floating-download {
  grid-template-rows: 2.8rem 1fr;
}

#root #infoCentre-portal-body .ui-mobile .ui-content {
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}

/***********icon****************/
#root #infoCentre-portal-body .ui-mobile .icon-menu {
  width: 0.72rem;
  height: 0.5rem;
  display: inline-block;
  background-image: url('../../static/html/icon/icon_menu.webp');
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon-message {
    background-image: url("../../static/html/svg/message.svg");
    background-size: 100% 100%;
    width: 0.70rem;
    height:     0.70rem;
    display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-history {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_history.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.61rem;
  height: 0.62rem;
  display: inline-block;
  position: absolute !important;
  right: 0.3rem;
  top: 0.3rem;
  z-index: 1001;
}

#root #infoCentre-portal-body .ui-mobile .icon-close {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_close.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-logo {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_logo.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 1.63rem;
  height: 0.91rem;
  display: inline-block;
}

.ui-mobile .icon-home-big {
  background-image: url("../../static/html/svg/home1.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-funds-big {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_funds_big.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-account-big {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_account_big.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-promotion-big {
  background-image: url("../../static/html/svg/promotion1.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-vip {
  background-image: url("../../static/html/svg/vip.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-contact {
  background-image: url("../../static/html/svg/contact.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

/* default language images */
#root #infoCentre-portal-body .ui-mobile .icon-language {
    background-image: url("../../static/html/language/malaysia.png");
    background-size: 100% 100%;
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
}

/* language images */
#root #infoCentre-portal-body .ui-mobile .icon-language-IDR {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/IDR.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

/* note: KHR is KHUSD currently */
#root #infoCentre-portal-body .ui-mobile .icon-language-KHUSD {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/KHR.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-language-MYR {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/MYR.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-language-SGD {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/SGD.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-language-THB {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/THB.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-language-USD {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/USD.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-language-VND {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/VND.jpg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-about-us {
  background-image: url("../../static/html/svg/about.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-leaderboard {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_menu_leaderboard.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-desktop {
  background-image: url("../../static/html/svg/desktop.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-download {
  background-image: url("../../static/html/svg/download.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-logout {
  background-image: url("../../static/html/svg/logout.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-dns-changer {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/other/icon_dns.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-other-games {
    background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/other/icon_other.png");
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
    width: 0.95rem;
    height: 0.86rem;
    display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-home {
  background-image: url("../../static/html/svg/home1.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.74rem;
  height: 0.67rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-funds {
  background-image: url("../../static/html/svg/funds.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.68rem;
  height: 0.67rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-account {
  background-image: url("../../static/html/svg/account.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.68rem;
  height: 0.67rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-promotion {
  background-image: url("../../static/html/svg/icon_promotion.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.68rem;
  height: 0.67rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-livechat {
  /*background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_livechat.png");*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.68rem;
  height: 0.69rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon_customer_service {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_customer_service.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-referral-program {
  background-image: url("../../static/html/svg/referral.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-win {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/com/icon_win.gif");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.55rem;
  height: 0.55rem;
  display: inline-block;
  margin-left: 0.1rem;
}

#root #infoCentre-portal-body .ui-mobile .icon-setting {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/other/icon_setting.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-sponsors {
  background-image: url("https://www.bk8myr.com/public/new_bk8/content/images/mobile/other/icon_menu_sponsor.png");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon-tnc {
  background-image: url("../../static/html/svg/tnc.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_deposit {
  width: 0.78rem;
  height: 0.75rem;
  display: inline-block;
  background: url(../../static/html/svg/deposit.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_transfer {
  width: 0.89rem;
  height: 0.74rem;
  display: inline-block;
  background: url(../../static/html/svg/transfer.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_restore {
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  background: url(../../static/html/svg/restore.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_withdraw {
  width: 0.78rem;
  height: 0.76rem;
  display: inline-block;
  background: url(../../static/html/svg/withdraw.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_promo {
  width: 0.78rem;
  height: 0.76rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_promotion.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_history {
  width: 0.69rem;
  height: 0.77rem;
  display: inline-block;
  background: url(../../static/html/svg/history.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off {
  width: 0.52rem;
  height: 0.42rem;
  display: inline-block;
  background: url(../../static/html/svg/eye-closed.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_on, #pageHome #homePage .idr_box .idr_content .idr_content_top .home_header_eyes.on, #fundsPage .user_info_box .funds_info_count #root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off.on,
#transferPage .user_info_box .funds_info_count #root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off.on,
#depositPage .user_info_box .funds_info_count #root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off.on,
#withDrawPage .user_info_box .funds_info_count #root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off.on {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: url(../../static/html/svg/eyes_on.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sports_off {
  width: 0.84rem;
  height: 0.83rem;
  display: inline-block;
  background: url(../../static/html/svg/sports.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sports_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_sports_off {
  width: 0.84rem;
  height: 0.83rem;
  display: inline-block;
  background: url(../../static/html/svg/sports.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livecasino_off {
  width: 0.91rem;
  height: 0.90rem;
  display: inline-block;
  background: url(../../static/html/svg/casino1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livecasino_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_livecasino_off {
  width: 0.91rem;
  height: 0.90rem;
  display: inline-block;
  background: url(../../static/html/svg/casino1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_slots_off {
  width: 1.08rem;
  height: 0.96rem;
  display: inline-block;
  background: url(../../static/html/svg/slots.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_slots_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_slots_off {
  width: 1.08rem;
  height: 0.96rem;
  display: inline-block;
  background: url(../../static/html/svg/slots.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_poker_off {
  width: 0.84rem;
  height: 0.83rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_poker_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_poker_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_poker_off {
  width: 0.84rem;
  height: 0.83rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_poker_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

/*Start esports css*/
#root #infoCentre-portal-body .ui-mobile .icon_home_menu_esports_off {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background: url(../../static/html/svg/esports.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_esports_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_esports_off {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  background: url(../../static/html/svg/esports.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}
/*End esports css*/

/*Start games css*/
#root #infoCentre-portal-body .ui-mobile .icon_home_menu_games_off {
  width: 1rem;
  height: 0.9rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_games_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_games_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_games_off {
  width: 1rem;
  height: 0.9rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_games_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}
/*End games css*/

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_fishing_off {
  width: 0.94rem;
  height: 0.62rem;
  display: inline-block;
  background: url(../../static/html/svg/fishing1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_fishing_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_fishing_off {
  width: 0.94rem;
  height: 0.62rem;
  display: inline-block;
  background: url(../../static/html/svg/fishing1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_lottery_off {
  width: 0.79rem;
  height: 0.89rem;
  display: inline-block;
  background: url(../../static/html/svg/lottery1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_lottery_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_lottery_off {
  width: 0.79rem;
  height: 0.89rem;
  display: inline-block;
  background: url(../../static/html/svg/lottery1.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_racing_off {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_racing_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_racing_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_racing_off {
  width: 1rem;
  height: 1rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_racing_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}
#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livetv_off {
  width: 0.77rem;
  height: 0.78rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livetv_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livetv_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_livetv_off {
  width: 0.77rem;
  height: 0.78rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_livetv_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sponsor_off {
  width: 0.79rem;
  height: 0.89rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sponsor_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sponsor_on, #pageHome #homePage .home_navBox .home_nav li .navTab.on #root #infoCentre-portal-body .ui-mobile .icon_home_menu_sponsor_off {
  width: 0.79rem;
  height: 0.89rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_menu_sponsor_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_feather {
  width: 0.55rem;
  height: 0.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_logo_feather.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_wap {
  width: 0.59rem;
  height: 0.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_logo_wap.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sg {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sg.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fw {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sgfw.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fishinggod.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ah {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sgah.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_zp {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sgzp.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_rf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jlrf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jlbf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jljf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_dt {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jldt.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_df {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jldf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bl {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jlbl.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jlmf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_hf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jlhf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_pt-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt2.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt2_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pt2_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pgs_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pgs.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jkr_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jkr.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_nt_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_nt.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_hb_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_hb.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jl_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_jl.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_rt_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_rt.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_hc_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_hc.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_wm {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_wm-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ppl {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ppl.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_provider_bg {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bg.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_provider_aes {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_sb-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_xa {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/xiaoai.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pg {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_pg-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mg {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mg.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ag {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_ag-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ag_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ag_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mgp {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mgp.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mgp_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mgp_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sa_fish {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sa_fish.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sa_casino {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_sa-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sa_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sa_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pp_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_pp_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mega_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mega_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_918 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_918.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ultimate {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ultimate.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_cmd {
    width: 100%;
    height: 100%;
    display: inline-block;
    /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_cmd.png) no-repeat;*/
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}
  

.list#root #infoCentre-portal-body .ui-mobile .icon_home_logo_cmd {
    width: 2.5rem
}

#root #infoCentre-portal-body .ui-mobile .icon_home_mb {
    width: 100%;
    height: 100%;
    display: inline-block;
    /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_mb.png) no-repeat;*/
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}

.list#root #infoCentre-portal-body .ui-mobile .icon_home_mb {
    width: 2.8rem;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_mb_wap {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_mb_wap.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb {
    width: 100%;
    height: 100%;
    display: inline-block;
    /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb.png) no-repeat;*/
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}
  

.list#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb {
  width: 2.7rem
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb_wap {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb_wap.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb_lite {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sb_lite.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bti {
    width: 100%;
    height: 100%;
    display: inline-block;
    /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bti.png) no-repeat;*/
    -webkit-background-size: 100% 100%;
            background-size: 100% 100%;
}

.list#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bti {
  width: 1.6rem;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bti_new {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_bti_new.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ambassador {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ambassador.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ambassador_2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ambassador_2.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ime {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ime.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_tf {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_tf.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gd {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gd.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_ab {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_ab-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_big{
    width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_bg-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_dream {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_dg-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_evo {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_evolution-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_xpro {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_xpro-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gp {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(../../static/html/product/home_cat_casino_gp-L.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gp_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gp_slot.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sexy {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-casino-sb.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gg {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gg.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qq {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qq.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qqk {
  width: 100%;
  height: 100%;
  display: inline-block;
 /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qqk.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qqkthai {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_qqkthai.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpfast3 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpfast3.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpkeno {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpkeno.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpladder {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpladder.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gprps {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gprps.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpsode {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpsode.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gptaixiu {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gptaixiu.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthor {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthor.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpluckyderby {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpluckyderby.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gppk10 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gppk10.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthailottery {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthailottery.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthaihilo {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthaihilo.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpxocdia {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpthaixocdia.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpprawncrab {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_gpprawncrab.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_atom {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_atom.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_keno {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_keno.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}


#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_rng {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fg_rng.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_cmdlottery {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_cmdlottery.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}
          
#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_4d {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_4d.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_6d {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_6d.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_sgwinlottery {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_sgwinlottery.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_gameplay {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_gameplay.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_t7 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_t7.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_funkygames {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_funkygames.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_qqkeno {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_lottery_qqkeno.png) no-repeat;/*
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_racing {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_racing.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_poker {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_t7_poker.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_idn_poker {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_idn_poker.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

/*Start games css*/
#root #infoCentre-portal-body .ui-mobile .icon_home_logo_taixiu {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_taixiu.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_xocdia {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_xocdia.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_vn_fish-prawn-crab {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_fpc1.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_sicbo {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_sicbo.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_keno {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_keno.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_thai-hi-lo2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_thhl.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_fish-prawn-crab2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_fpc2.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

/* #root #infoCentre-portal-body .ui-mobile .icon_home_logo_fan-tan2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_fantan.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
} */

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_dragon-tiger2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_dragontiger.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_belangkai2 {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_belangkai.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_thai-fpc {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_tfpc.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_kingmaker {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_kingmaker.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_gameplay {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_gameplay.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_v8poker {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_v8poker.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_jili {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_games_jili.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
          background-position: center;
}
/*End games css*/

#root #infoCentre-portal-body .ui-mobile .icon_home_click_pop_uppagcor {
  width: 3.43rem;
  height: 0.88rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_click_pop_uppagcor.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_barcelona {
  width: 1.14rem;
  height: 1.15rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_barcelona.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_laliga {
  width: 0.96rem;
  height: 1.38rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_laliga.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_official_license {
  width: 1.14rem;
  height: 1.14rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_official_license.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_real_madrid {
  width: 1.02rem;
  height: 1.43rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home#root #infoCentre-portal-body .ui-mobile .icon_home_footer_logo_real_madrid.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_nsp_slot {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms#root #infoCentre-portal-body .ui-mobile .icon_home_logo_nsp.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_notice {
  width: 0.73rem;
  height: 0.59rem;
  display: inline-block;
  background: url(../../static/html/icon/icon_annoucement.webp) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

/* Start Sponsor */
#root #infoCentre-portal-body .ui-mobile .icon_home_logo_current_ambassador {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-rvp.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_previous_ambassador {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-jt.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_principal_partner {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-ncfc.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_regional_partner {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-mcfc.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_betting_partner {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-lcfc.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_event {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/firms/provider-sponsor-event.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_huddersfield {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/custom_content/images/sponsorMenu/m-menu-huddersfield.png) no-repeat;*/
  -webkit-background-size: 75%;
          background-size: 75%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_kellySmith {
  width: 100%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/custom_content/images/sponsorMenu/m-menu-kelly-smith.png) no-repeat;*/
  -webkit-background-size: 100%;
          background-size: 100%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_valencia {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/images/sponsorMenu/m-menu-valencia.png) no-repeat;*/
  -webkit-background-size: 80%;
          background-size: 80%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_athletic_club {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/images/sponsorMenu/m-menu-athletic.png) no-repeat;*/
  -webkit-background-size: 80%;
          background-size: 80%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_villarreal {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/images/sponsorMenu/m-menu-villarreal.png) no-repeat;*/
  -webkit-background-size: 80%;
          background-size: 80%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_elche {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/images/sponsorMenu/m-menu-elche.png) no-repeat;*/
  -webkit-background-size: 80%;
          background-size: 80%;
  background-position: center;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_logo_mallorca {
  width: 80%;
  height: 100%;
  display: inline-block;
  /*background: url(../../static/html/images/sponsorMenu/m-menu-mallorca.png) no-repeat;*/
  -webkit-background-size: 80%;
          background-size: 80%;
  background-position: center;
}


#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_live_chat {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_menu_contact_live_chat.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_phone {
  width: 0.66rem;
  height: 0.65rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_phone.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_wechat {
  width: 0.92rem;
  height: 0.76rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_menu_contact_wechat.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_whatsapp {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_menu_contact_whatsapp.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_telegram {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_menu_contact_telegram.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_yahoo {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_yahoo.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_skype {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_skype.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_line {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_line.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_bbm {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_bbm.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_zalo {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_zalo.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_viber {
  width: 0.91rem;
  height: 0.90rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_viber.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_email {
  width: 0.69rem;
  height: 0.68rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/contactus#root #infoCentre-portal-body .ui-mobile .icon_menu_contact_email.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon-tnc {
  background-image: url("../../static/html/svg/tnc.svg");
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  width: 0.65rem;
  height: 0.70rem;
  display: inline-block;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_deposit {
  width: 1.16rem;
  height: 1.11rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_funds_deposit.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_transfer {
  width: 1.32rem;
  height: 1.1rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_funds_transfer.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_withdraw {
  width: 1.16rem;
  height: 1.13rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_funds_withdraw.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_history {
  width: 1.02rem;
  height: 1.14rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_funds_history.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_instant_rebate {
  width: 1.16rem;
  height: 1.13rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/fund/icon_funds_rebate.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_restore_wallet {
  width: 0.7rem;
  height: 0.7rem;
  display: inline-block;
  background: url(../../static/html/svg/restore.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_main_wallet_auto_transfer {
  width: 0.9rem;
  height: 0.9rem;
  display: inline-block;
  background: url(../../static/html/svg/transfer.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_add {
  width: 0.68rem;
  height: 0.67rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/fund/icon_funds_add.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_funds_less {
  width: 0.68rem;
  height: 0.67rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/fund/icon_funds_less.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_off {
  width: 0.52rem;
  height: 0.42rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/home/icon_home_header_eyes_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_on, #pageHome #homePage .idr_box .idr_content .idr_content_top .home_header_eyes.on, #fundsPage .user_info_box .funds_info_count .icon_home_header_eyes_off.on,
#root #infoCentre-portal-body .ui-mobile #transferPage .user_info_box .funds_info_count .icon_home_header_eyes_off.on,
#root #infoCentre-portal-body .ui-mobile #depositPage .user_info_box .funds_info_count .icon_home_header_eyes_off.on,
#root #infoCentre-portal-body .ui-mobile #withDrawPage .user_info_box .funds_info_count .icon_home_header_eyes_off.on {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: url(../../static/html/svg/eyes_on.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile #pageHome #homePage .idr_box .idr_content .idr_content_top .home_header_eyes.on {
  height: 0.52rem;
  background: url(../../static/html/svg/eyes_on.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_home_header_eyes_on, #pageHome #homePage .idr_box .idr_content .idr_content_top .home_header_refresh, 
#root #infoCentre-portal-body .ui-mobile #fundsPage .user_info_box .funds_info_count .home_header_refresh,
#root #infoCentre-portal-body .ui-mobile #transferPage .user_info_box .funds_info_count .home_header_refresh,
#root #infoCentre-portal-body .ui-mobile #depositPage .user_info_box .funds_info_count .home_header_refresh,
#root #infoCentre-portal-body .ui-mobile #withDrawPage .user_info_box .funds_info_count .home_header_refresh {
  width: 0.32rem;
  height: 0.32rem;
  margin-top: 1.2rem;
  display: inline-block;
  margin: 0;
  background: url(../../static/html/svg/refresh.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_user {
  width: 0.49rem;
  height: 0.69rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account#root #infoCentre-portal-body .ui-mobile .icon_my_account_user.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_member_group {
  width: 1.4rem;
  height: 1.6rem;
  object-fit: contain;
  display: inline-block;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_leaderboard_ranking {
  width: 1.48rem;
  height: 1.48rem;
  object-fit: contain;
  display: inline-block;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
  margin-bottom: 0.05rem;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_normal {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-normal.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_bronze {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-bronze.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_silver {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-silver.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_gold {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-gold.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_platinum {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-platinum.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_my_account_user_diamond {
  width: 1.4rem;
  height: 1.6rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/../content/images/mobile/account/user-diamond.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_my_account_star_on {
  width: 0.27rem;
  height: 0.26rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account#root #infoCentre-portal-body .ui-mobile .icon_my_account_star_on.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_star_off {
  width: 0.27rem;
  height: 0.26rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account#root #infoCentre-portal-body .ui-mobile .icon_my_account_star_off.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_edit_profile {
  width: 1.6rem;
  height: 1.4rem;
  display: inline-block;
  background: url(../../static/html/svg/profile.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_change_password {
  width: 1.46rem;
  height: 1.38rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_cpassword.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_change_game_password {
  width: 1.25rem;
  height: 1.18rem;
  display: inline-block;
  background: url(../../static/html/svg/cgamepass.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_banking_details {
  width: 1.25rem;
  height: 1.18rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_withdraw.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_my_account_messaging {
  width: 1.65rem;
  height: 1.3rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_message.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mbb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url('../../static/html/bank/bank-MBBI.svg') no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_cimb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-CIMBN.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_affin {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-AFFIN.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_alliance {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-ALLIANCE.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_hlb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-HLB.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_pbb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-PBE.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}


#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_rhb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-RHB.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_dbs {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_eeziepay {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_gjkfyh {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_gsb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_h2p {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_hsbc {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-HSBC.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_kkr {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_ktb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mdr {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_ocbc {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-OCBC.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_pmb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_posb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_scb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_scbt {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tmb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_uob {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-UOB.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_zgjckyh {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_zgnyfzyh {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_zgrmyh {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_amb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-AMB.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bay {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bbl {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bca {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bdmn {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bimb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bni {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_boa {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_br {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-RKY.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bri {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_acb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_agb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bidv {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_dab {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_exim {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_sacom {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_scmb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tcb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_vcb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_vib {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_vpb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_vtb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mscb {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_btn {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_btpn {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_nisp {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_pnbn {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_citi {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-CITI.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_bankApps_uob {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-UOB.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_bankApps_dbs {
    width: 1.01rem;
    height: 1.01rem;
    display: inline-block;
    background: url(../../static/html/bank/other.png) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_bankApps_tpb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_close {
  width: 0.8rem;
  height: 0.8rem;
  display: inline-block;
  background: url(../../static/html/images/icon_language_close.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

.icon_fund_deposit_copy {
  width: 0.63rem;
  height: 0.69rem;
  display: inline-block;
  background: url(../../static/html/images/icon_fund_deposit_copy.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

.icon_my_referral {
  width: 1.25rem;
  height: 1.18rem;
  display: inline-block;
  background: url(../../static/html/svg/icon_referral.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile #pageHome #homePage .idr_box .idr_content .idr_content_top .home_header_refresh {
  background: url(../../static/html/svg/refresh.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_fund_history_arrow {
  width: 0.48rem;
  height: 0.32rem;
  display: inline-block;
  background: url(../../static/html/images/arrow.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_fund_history_search {
  width: 0.61rem;
  height: 0.62rem;
  display: inline-block;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_fund_history_searchclose {
  width: 0.48rem;
  height: 0.48rem;
  display: inline-block;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_fund_deposit_copy {
  width: 0.63rem;
  height: 0.69rem;
  display: inline-block;
  background: url(../../static/html/images/icon_fund_deposit_copy.png) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_afb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-AFFIN.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bsn {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-BSN.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_ago {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-AGRO.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_alb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-ALB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_scb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-SCB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_scbs {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-SCBS.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_citisg {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-CITISG.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tm {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-TM.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tbk {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-TBK.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_ghb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-GHB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_lhb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-LHB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_sdcb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-SDCB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_baac {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-BAAC.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_kkp {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-KKP.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_deut {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-DEUT.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tisco {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-TISCO.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_isbt {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-ISLAM.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mbbi {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-MBBI.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tel {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-TEL.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_xla {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-XLA.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_icbc {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-ICBC.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_dana {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-DANA.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_gp {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-GP.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_ovo {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-OVO.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_boc {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-BOC.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_bnpp {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-BNPP.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mega {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-MEGA.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_mhcb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-MHCB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_smbc {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-SMBC.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_tcrb {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/account/bank-TCRB.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_myaccount_banking_otherBank {
  width: 1.01rem;
  height: 1.01rem;
  display: inline-block;
  background: url(../../static/html/bank/other-bank.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_language_asia {
  width: 0.94rem;
  height: 0.95rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/language/asia.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icon_language_cambodia {
  width: 0.94rem;
  height: 0.95rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/language/cambodia.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #infoCentre-portal-body .ui-mobile .icons_language_international {
  width: 0.94rem;
  height: 0.95rem;
  display: inline-block;
  /*background: url(https://www.bk8myr.com/public/new_bk8/content/images/mobile/language/international.png) no-repeat;*/
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
