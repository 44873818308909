.common-title,
#root #ui-desktop .my-collection .title-box .item-title,
#root #ui-desktop .dropping-hot .item-title,
#root #ui-desktop .donot-miss .item-title {
    font-size: 0.25rem;
    font-family: sans-serif;
    font-weight: 900;
    line-height: 1.1rem;
    text-transform: uppercase;
}

#root #ui-desktop {
    padding-top: 1.32rem;
    background: #111;
}

html * {
    margin: 0;
    padding: 0;
    list-style: none;
    outline: none;
    box-sizing: border-box;
    font-family: sans-serif;
}

html body {
    overflow-x: hidden;
    font-size: 16px;
    letter-spacing: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
}

html {
    height: 100%;
    background: #191919;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}

html ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    display: none;
}

html ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px red;
    background: red;
}

html ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0,0,0,0);
    border-radius: 10px;
    background-color: transparent;
}

html body a {
    text-decoration: none;
    color: #3f51b5;
}

html body a:visited {
    text-decoration: none;
}

html body img {
    user-select: none;
}

.hide {
    display: none !important;
}

.loading-container {
    z-index: 1;
    height: 100vh;
    width: 100vw;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgb(0 0 0 / 68%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.small-loading {
    fill: gray;
    color: gray;
}

.loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loading img {
    width: 100%;
    height: 100%;
    max-width: 3rem;
}

.loading #logo {
    width: 3rem;
}

.loading #logo path:nth-child(1) {
    stroke-dasharray: 635.7127685546875;
    stroke-dashoffset: 635.7127685546875;
    animation: line-anime1 3s ease backwards infinite;
}

.loading #logo path:nth-child(2) {
    stroke-dasharray: 565.9503173828125;
    stroke-dashoffset: 565.9503173828125;
    animation: line-anime2 3s ease backwards infinite;
}

.loading #logo path:nth-child(3) {
    stroke-dasharray: 571.5772705078125;
    stroke-dashoffset: 571.5772705078125;
    animation: line-anime3 3s ease backwards infinite;
}

.loading #logo path:nth-child(4) {
    stroke-dasharray: 565.3145751953125;
    stroke-dashoffset: 565.3145751953125;
    animation: line-anime4 3s ease backwards infinite;
}

.loading #logo path:nth-child(5) {
    stroke-dasharray: 565.314697265625;
    stroke-dashoffset: 565.314697265625;
    animation: line-anime5 3s ease backwards infinite;
}

@keyframes line-anime1 {
    0% {
        stroke-dashoffset: 0;
        fill: gray;
    }

    100% {
        stroke-dashoffset: 417.5157775878906;
        fill: transparent;
    }
}

@keyframes line-anime2 {
    0% {
        stroke-dashoffset: 0;
        fill: gray;
    }

    100% {
        stroke-dashoffset: 495.2247619628906;
        fill: transparent;
    }
}

@keyframes line-anime3 {
    0% {
        stroke-dashoffset: 0;
        fill: gray;
    }

    100% {
        stroke-dashoffset: 542.675048828125;
        fill: transparent;
    }
}

@keyframes line-anime4 {
    0% {
        stroke-dashoffset: 0;
        fill: gray;
    }

    100% {
        stroke-dashoffset: 495.22296142578125;
        fill: transparent;
    }
}

@keyframes line-anime5 {
    0% {
        stroke-dashoffset: 0;
        fill: gray;
    }

    100% {
        stroke-dashoffset: 789.8562622070312;
        fill: transparent;
    }
}

@media (prefers-reduced-motion: reduce) {
    #root #ui-desktop .notice span {
        animation-iteration-count: 1;
        animation-duration: 0.01;
        width: auto;
        padding-left: 0;
    }
}

/*icon start*/
#root #ui-desktop .icon_myaccount_banking_mbb {
    width: 0.4rem;
    height: 0.4rem;
    display: inline-block;
    background: url(../../static/html/bank/bank-MBBI.svg) no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_hlb {
  width: 0.4rem;
    height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-HLB.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_cimb {
  width: 0.4rem;
    height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-CIMBN.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_pbb {
  width: 0.4rem;
    height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-PBE.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_rhb {
  width: 0.5rem;
    height: 0.5rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-RHB.svg) no-repeat;
  -webkit-background-size: 100% 100%;
          background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_bsn {
    width: 0.4rem;
    height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/bank-BSN.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #ui-desktop .icon-deposit-tron{
  width: 0.4rem;
  height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/tether-usdt-logo.png) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

#root #ui-desktop .icon_myaccount_banking_otherBank{
  width: 0.4rem;
  height: 0.4rem;
  display: inline-block;
  background: url(../../static/html/bank/other-bank.svg) no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon-date {
    display: inline-block;
    width: 0.19rem;
    height: 0.19rem;
    background-image: url(../../static/html/icon/date.png);
    background-size: 100% 100%;
}

.icon-deposit-payessence {
    display: inline-block;
    width: 0.64rem;
    height: 0.57rem;
    background-image: url(../../static/html/bank/payessence.jpg);
    background-size: 100% 100%;
}

.icon-hover-box:hover .icon-deposit-payessence, .icon-hover-box.on .icon-deposit-payessence {
    background-image: url(../../static/html/bank/payessence.jpg);
}

.icon-deposit-crypto {
    display: inline-block;
    width: 0.28rem;
    height: 0.28rem;
    background-image: url(../../static/html/icon/usdt.svg);
    filter: grayscale(100%);
    background-size: 100% 100%;
}

.icon-hover-box:hover .icon-deposit-crypto, .icon-hover-box.on .icon-deposit-crypto {
    background-image: url(../../static/html/icon/usdt.svg);
    filter: grayscale(0%);
}

.icon-deposit-quickpay {
    display: inline-block;
    width: 0.4rem;
    height: 0.4rem;
    background-image: url("../../static/html/icon/quickpay.svg");
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-hover-box:hover .icon-deposit-quickpay, .icon-hover-box.on .icon-deposit-quickpay {
    background-image: url(../../static/html/icon/quickpay.svg);
    filter: grayscale(0%);
}

.icon-deposit-bank {
    display: inline-block;
    width: 0.3rem;
    height: 0.24rem;
    background-image: url(../../static/html/icon/bank.png);
    background-size: 100% 100%;
}

.icon-hover-box:hover .icon-deposit-bank, .icon-hover-box.on .icon-deposit-bank {
    background-image: url(../../static/html/icon/bank.png);
}

.icon-level {
    display: inline-block;
    width: 0.77rem;
    height: 0.47rem;
    background-size: 100% 100%;
}

.icon-transfer {
    display: inline-block;
    width: 0.77rem;
    height: 0.36rem;
    background-image: url(../../static/html/icon/transfer.png);
    background-size: 100% 100%;
}

.icon-transfer.on {
    background-image: url(../../static/html/icon/transfer_on.png);
}

.icon-reload {
    display: inline-block;
    width: 0.24rem;
    height: 0.24rem;
    fill: #ff4e00;
}

.icon-restore {
    display: inline-block;
    width: 0.33rem;
    height: 0.37rem;
    fill: #ff4e00;
}

.icon-logo {
    display: inline-block;
    width: 1.6rem;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(../../static/html/images/maxbook55_logo.png);
}

.icon-step-1 {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    background-image: url(../../static/html/menu/step1.png);
    background-size: 100% 100%;
}

.icon-step-2 {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    background-image: url(../../static/html/menu/step2.png);
    background-size: 100% 100%;
}

.icon-step-3 {
    display: inline-block;
    width: 0.7rem;
    height: 0.7rem;
    background-image: url(../../static/html/menu/step3.png);
    background-size: 100% 100%;
}

.login-page-box-enter {
    opacity: 0;
}

.login-page-box-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
}

.login-page-box-exit {
    opacity: 1;
}

.login-page-box-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
}

.icon-cmd368 {
    display: inline-block;
    width: 1.29rem;
    height: 0.26rem;
    background-image: url(../../static/html/sport/logo1.png);
    background-size: 100% 100%;
}

.icon-ibc {
    display: inline-block;
    width: 1.71rem;
    height: 0.41rem;
    background-image: url(../../static/html/sport/logo2.png);
    background-size: 100% 100%;
}

.icon-united {
    display: inline-block;
    width: 2rem;
    height: 0.5rem;
    background-image: url(../../static/html/sport/logo3.png);
    background-size: 100% 100%;
}

.icon-game-common {
    display: inline-block;
    width: 1.81rem;
    height: 1.18rem;
    background-size: 100% 100%;
}

.icon-logo {
    display: inline-block;
    height: 1rem;
    background-size: contain;
    background-repeat: no-repeat;
}

.icon-logo-footer {
    display: inline-block;
    width: 1rem;
    height: 0.26rem;
    background-size: 100% 100%;
}

.icon-refesh {
    display: inline-block;
    width: 0.19rem;
    height: 0.19rem;
    background: url(../../static/html/icon/refesh.png) no-repeat;
    background-size: 100% 100%;
}

.icon-arrow {
    display: inline-block;
    width: 0.13rem;
    height: 0.06rem;
    background: url(../../static/html/icon/arrow.png) no-repeat;
    background-size: 100% 100%;
}

.icon-arrow2 {
    display: inline-block;
    width: 0.13rem;
    height: 0.08rem;
    background-size: 100% 100%;
}

.icon-bmm {
    display: inline-block;
    width: 0.55rem;
    height: 0.17rem;
    background-image: url(../../static/html/svg/bmm.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-bmm:hover {
    filter: grayscale(0%);
}

.icon-bank-icon-ftr {
    display: inline-block;
    width: 0.3rem;
    height: 0.28rem;
    background-size: 100% 100%;
}

.icon-ethereum {
    display: inline-block;
    width: 0.28rem;
    height: 0.28rem;
    background-size: 100% 100%;
}

.icon-mobifone {
    display: inline-block;
    width: 0.97rem;
    height: 0.22rem;
    background-size: 100% 100%;
}

.icon-momo {
    display: inline-block;
    width: 0.26rem;
    height: 0.26rem;
    background-size: 100% 100%;
}

.icon-viettel {
    display: inline-block;
    width: 0.45rem;
    height: 0.26rem;
    background-size: 100% 100%;
}

.icon-viettelpay {
    display: inline-block;
    width: 0.35rem;
    height: 0.32rem;
    background-size: 100% 100%;
}

.icon-vinaphone {
    display: inline-block;
    width: 1.2rem;
    height: 0.29rem;
    background-size: 100% 100%;
}

.icon-zalo {
    display: inline-block;
    width: 0.27rem;
    height: 0.25rem;
    background-size: 100% 100%;
}

.icon-eeziepay {
    display: inline-block;
    width: 0.77rem;
    height: 0.32rem;
    background-image: url(../../static/html/svg/EeziePay.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-eeziepay:hover {
    filter: grayscale(0%);
}

.icon-facebook {
    display: inline-block;
    width: 0.29rem;
    height: 0.29rem;
    background-image: url(../../static/html/svg/fb.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-facebook:hover {
    cursor: pointer;
    filter: grayscale(0%);
}

.icon-instagram {
    display: inline-block;
    width: 0.29rem;
    height: 0.29rem;
    background-image: url(../../static/html/svg/instagram.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-instagram:hover {
    cursor: pointer;
    filter: grayscale(0%);
}

.icon-android-footer {
    display: inline-block;
    width: 0.29rem;
    height: 0.29rem;
    fill: #a2aabd;
}

.icon-android-footer:hover {
    fill: #85c808;
    opacity: 1 !important;
    cursor: pointer;
}

.icon-ios-footer {
    display: inline-block;
    width: 0.29rem;
    height: 0.29rem;
    fill: #a2aabd;
}

.icon-ios-footer:hover {
    fill: #efefef;
    opacity: 1 !important;
    cursor: pointer;
}

.icon-global {
    display: inline-block;
    width: 0.42rem;
    height: 0.42rem;
    background-image: url(../../static/html/svg/gli.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-global:hover {
    filter: grayscale(0%);
}

.icon-godaddy {
    display: inline-block;
    width: 1.35rem;
    height: 0.26rem;
    background-image: url(../../static/html/svg/godaddy.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-godaddy:hover {
    filter: grayscale(0%);
}

.icon-help2pay {
    display: inline-block;
    width: 0.37rem;
    height: 0.26rem;
    background-image: url(../../static/html/svg/Help2Pay.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-help2pay:hover {
    filter: grayscale(0%);
}

.icon-iovation {
    display: inline-block;
    width: 1.19rem;
    height: 0.24rem;
    background-image: url(../../static/html/svg/iovation.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-iovation:hover {
    filter: grayscale(0%);
}

.icon-itechlab {
    display: inline-block;
    width: 0.42rem;
    height: 0.4rem;
    background-image: url(../../static/html/svg/itechlabs.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-itechlab:hover {
    filter: grayscale(0%);
}

.icon-pagcor {
    display: inline-block;
    width: 1.25rem;
    height: 0.28rem;
  /*background-image: url(../../html/images/footer/pagcor-gray.png);*/
    background-size: 100% 100%;
}

.icon-curacao {
    display: inline-block;
    width: 1.1rem;
    height: 0.48rem;
    background-image: url(../../static/html/svg/ftr_curacao_grey.svg);
    background-size: 100% 100%;
}

.icon-curacao:hover {
    cursor: pointer;
    background-image: url(../../static/html/svg/ftr_curacao.svg);
}

.icon-bitcoin {
    display: inline-block;
    width: 0.275rem;
    height: 0.275rem;
    background-image: url(../../static/html/svg/Bitcoin.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-bitcoin:hover {
    filter: grayscale(0%);
}

.icon-tng {
    display: inline-block;
    width: 0.275rem;
    height: 0.275rem;
    background-image: url(../../static/html/svg/TouchNGo.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-tng:hover {
    filter: grayscale(0%);
}

.icon-usdt {
    display: inline-block;
    width: 0.275rem;
    height: 0.275rem;
    background-image: url(../../static/html/svg/USDT.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-usdt:hover {
    filter: grayscale(0%);
}

.icon-payouts {
    display: inline-block;
    width: 0.83rem;
    height: 0.29rem;
    background-image: url(../../static/html/svg/tst.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-payouts:hover {
    filter: grayscale(0%);
}

.icon-paytrust {
    display: inline-block;
    width: 0.9rem;
    height: 0.19rem;
  /*background-image: url(../../html/images/footer/paytrust-gray.png);*/
    background-size: 100% 100%;
}

.icon-youtube {
    display: inline-block;
    width: 0.3rem;
    height: 0.29rem;
    background-image: url(../../static/html/svg/youtube.svg);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-youtube:hover {
    cursor: pointer;
    filter: grayscale(0%);
}

.icon-18 {
    display: inline-block;
    width: 0.27rem;
    height: 0.25rem;
    background-image: url(../../static/html/svg/18+.svg);
    background-size: 100% 100%;
    filter: grayscale(0%);
}

.icon-close2 {
    display: inline-block;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url("../../static/html/payment/close.jpg");
    background-size: 100% 100%;
}

.icon-close3 {
    display: inline-block;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url("../../static/html/payment/close.jpg");
    background-size: 100% 100%;
}

.icon-logo2 {
    display: inline-block;
    width: 3rem;
    height: 1.3rem;
    background-image: url("../../static/html/images/maxbook55_logo.png");
    background-size: 100% 100%;
}

.icon-username {
    display: inline-block;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url("../../static/html/icon/username.png");
    background-size: 100% 100%;
}

.icon-lock {
    display: inline-block;
    width: 0.34rem;
    height: 0.34rem;
    background-image: url('../../static/html/icon/lock.png');
    background-size: 100% 100%;
}

.icon-ibc-big {
    display: inline-block;
    width: 3.31rem;
    height: 0.77rem;
    background-image: url(../../static/html/sport/ibc_big.png);
    background-size: 100% 100%;
}

.icon-small-ibc {
    display: inline-block;
    width: 1.4rem;
    height: 0.37rem;
    background-image: url("../../static/html/sport/ibc.png");
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-cmd {
    display: inline-block;
    width: 3rem;
    height: 0.79rem;
    background-image: url(../../static/html/sport/cmd_big.png);
    background-size: 100% 100%;
}

.icon-small-cmd {
    display: inline-block;
    width: 1.4rem;
    height: 0.37rem;
    background-image: url(../../static/html/sport/cmd.png);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-bti {
    display: inline-block;
    width: 2.35rem;
    height: 1.05rem;
    background-image: url(../../static/html/sport/unitedi_big.png);
    background-size: 100% 100%;
}

.icon-small-unitedi {
    display: inline-block;
    width: 1.05rem;
    height: 0.45rem;
    background-image: url(../../static/html/sport/unitedi.png);
    background-size: 100% 100%;
    filter: grayscale(100%);
}

.icon-small-wm {
    display: inline-block;
    width: 1.2rem;
    height: 0.37rem;
    background-image: url("../../static/html/casino/wm.png");
    background-size: 100% 100%;
}

.icon-small-ae_sexy {
    display: inline-block;
    width: 1.3rem;
    height: 0.45rem;
    background-image: url("../../static/html/casino/ae_sexy.png");
    background-size: 100% 100%;
}

.icon-small-ag {
    display: inline-block;
    width: 1.07rem;
    height: 0.35rem;
    background-image: url("../../static/html/casino/ag.png");
    background-size: 100% 100%;
}

.icon-small-allbet {
    display: inline-block;
    width: 0.83rem;
    height: 0.48rem;
    background-image: url("../../static/html/casino/allbet.png");
    background-size: 100% 100%;
}

.icon-small-dream_gaming {
    display: inline-block;
    width: 0.79rem;
    height: 0.48rem;
    background-image: url("../../static/html/casino/dream_gaming.png");
    background-size: 100% 100%;
}

.icon-small-ebet {
    display: inline-block;
    width: 1.03rem;
    height: 0.32rem;
    background-image: url("../../static/html/casino/ebet.png");
    background-size: 100% 100%;
}

.icon-small-evolution {
    display: inline-block;
    width: 1.05rem;
    height: 0.35rem;
    background-image: url("../../static/html/casino/evolution.png");
    background-size: 100% 100%;
}

.icon-small-gameplay {
    display: inline-block;
    width: 1.22rem;
    height: 0.24rem;
    background-image: url("../../static/html/casino/gameplay.png");
    background-size: 100% 100%;
}

.icon-small-gd {
    display: inline-block;
    width: 0.96rem;
    height: 0.54rem;
  /*background-image: url("../../static/html/casino/gd.png");*/
    background-size: 100% 100%;
}

.icon-small-ppl {
    display: inline-block;
    width: 1.2rem;
    height: 0.55rem;
    background-image: url("../../static/html/casino/ppl.png");
    background-size: 100% 100%;
}

.icon-small-xprogaming {
    display: inline-block;
    width: 1.2rem;
    height: 0.55rem;
    background-image: url("../../static/html/casino/xprogaming.png");
    background-size: 100% 100%;
}

.icon-small-mg {
    display: inline-block;
    width: 1.3rem;
    height: 0.35rem;
    background-image: url("../../static/html/casino/mg.png");
    background-size: 100% 100%;
}

.icon-small-playtech {
    display: inline-block;
    width: 1.22rem;
    height: 0.25rem;
    background-image: url("../../static/html/casino/playtech.png");
    background-size: 100% 100%;
}

.icon-small-biggaming {
    display: inline-block;
    width: 1.22rem;
    height: 0.25rem;
    background-image: url("../../static/html/casino/biggaming.png");
    background-size: 100% 100%;
}

.icon-small-sa_gaming {
    display: inline-block;
    width: 1.08rem;
    height: 0.38rem;
    background-image: url("../../static/html/casino/sa_gaming.png");
    background-size: 100% 100%;
}

.icon-brand-ns {
    display: inline-block;
    width: 0.37rem;
    height: 0.25rem;
    background-image: url("../../static/html/slots/nextspin.png");
    background-size: 100% 100%;
}

.icon-brand-sg {
    display: inline-block;
    width: 0.29rem;
    height: 0.31rem;
    background-image: url(../../static/html/slots/spadegaming.png);
    background-size: 100% 100%;
}

.icon-brand-pp {
    display: inline-block;
    width: 0.3rem;
    height: 0.41rem;
    background-image: url("../../static/html/slots/pragmaticplay.png");
    background-size: 100% 100%;
}

.icon-brand-mega {
    display: inline-block;
    width: 0.32rem;
    height: 0.16rem;
    background-image: url("../../static/html/slots/mega888.png");
    background-size: 100% 100%;
}

.icon-brand-scr {
    display: inline-block;
    width: 0.4rem;
    height: 0.22rem;
    background-image: url(../../static/html/slots/918kiss.png);
    background-size: 100% 100%;
}

.icon-brand-jkr {
    display: inline-block;
    width: 0.34rem;
    height: 0.18rem;
    background-image: url(../../static/html/slots/joker.png);
    background-size: 100% 100%;
}

.icon-brand-hb {
    display: inline-block;
    width: 0.24rem;
    height: 0.39rem;
    background-image: url(../../static/html/slots/habanero.png);
    background-size: 100% 100%;
}

.icon-brand-nt {
    display: inline-block;
    width: 0.32rem;
    height: 0.16rem;
    background-image: url("../../static/html/slots/netent.png");
    background-size: 100% 100%;
}

.icon-brand-mgp {
    display: inline-block;
    width: 0.28rem;
    height: 0.28rem;
    background-image: url("../../static/html/slots/microgaming.png");
    background-size: 100% 100%;
}

.icon-brand-pt {
    display: inline-block;
    width: 0.34rem;
    height: 0.22rem;
    background-image: url("../../static/html/slots/playtech.png");
    background-size: 100% 100%;
}

.icon-brand-cq9 {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/cq9.png");
    background-size: 100% 100%;
}

.icon-brand-xe88 {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/xe88.png");
    background-size: 100% 100%;
}

.icon-brand-xin {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/xingaming.png");
    background-size: 100% 100%;
}

.icon-brand-simple {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/simpleplay.png");
    background-size: 100% 100%;
}

.icon-brand-ace {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/acewin.png");
    background-size: 100% 100%;
}

.icon-brand-mario {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/marioclub.png");
    background-size: 100% 100%;
}

.icon-brand-evo888 {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/evo888.png");
    background-size: 100% 100%;
}

.icon-brand-pussy888 {
    display: inline-block;
    width: 0.28rem;
    height: 0.27rem;
    background-image: url("../../static/html/slots/pussy888.png");
    background-size: 100% 100%;
}

.text-center {
    text-align: center;
}
/*icon end*/

/*datetime React start*/
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    margin-left: -0.08rem;
    position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    box-sizing: content-box;
    position: absolute;
    border: 0.08rem solid transparent;
    height: 0;
    width: 0.01rem;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    content: "";
    z-index: -1;
    border-width: 0.08rem;
    left: -0.08rem;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    top: 0;
    margin-top: -0.08rem;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    top: -0.01rem;
    border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    bottom: 0;
    margin-bottom: -0.08rem;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    border-bottom: none;
    border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
    bottom: -0.01rem;
    border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
}

.react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #fff;
    color: #000;
    border: 0.01rem solid #aeaeae;
    display: inline-block;
    position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
    left: 0.35rem;
}

.react-datepicker--time-only .react-datepicker__time-container {
    border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
    border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
    border-radius: 0.3rem;
}

.react-datepicker__triangle {
    position: absolute;
    left: 0.5rem;
}

.react-datepicker-popper {
    z-index: 5;
}

.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0.1rem;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
    left: auto;
    right: 0.5rem;
}

.react-datepicker-popper[data-placement^="top"] {
    margin-bottom: 0.1rem;
}

.react-datepicker-popper[data-placement^="right"] {
    margin-left: 0.08rem;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
    left: auto;
    right: 0.42rem;
}

.react-datepicker-popper[data-placement^="left"] {
    margin-right: 0.08rem;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
    left: 0.42rem;
    right: auto;
}

.react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 0.01rem solid #aeaeae;
    padding-top: 0.08rem;
    position: relative;
}

.react-datepicker__header--time {
    padding-bottom: 0.08rem;
    padding-left: 0.05rem;
    padding-right: 0.05rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
    display: inline-block;
    margin: 0 0.02rem;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.react-datepicker__navigation {
    background: none;
    line-height: 0.1rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0.1rem;
    width: 0;
    padding: 0;
    border: 0.1rem solid transparent;
    z-index: 1;
    height: 0.1rem;
    width: 0.1rem;
    text-indent: -999em;
    overflow: hidden;
}

.react-datepicker__navigation--previous {
    left: 0.1rem;
    border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--next {
    right: 0.1rem;
    border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 0.8rem;
}

.react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years-previous {
    top: 0.04rem;
    border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
    top: -0.04rem;
    border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
    float: left;
}

.react-datepicker__year-container {
    margin: 0.4rem;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
}

.react-datepicker__year-container-text {
    display: inline-block;
    cursor: pointer;
    flex: 1 0 30%;
    width: 0.12rem;
    padding: 0.02rem;
}

.react-datepicker__month {
    margin: 0.1rem;
    text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4rem;
    margin: 0.02rem;
}

.react-datepicker__input-time-container {
    clear: both;
    width: 100%;
    float: left;
    margin: 0.05rem 0 0.1rem 0.15rem;
    text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
    display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
    display: inline-block;
    margin-left: 0.1rem;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 0.85rem;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
    -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
    margin-left: 0.05rem;
    display: inline-block;
}

.react-datepicker__time-container {
    float: right;
    border-left: 0.01rem solid #aeaeae;
    width: 0.85rem;
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 0.01rem solid #aeaeae;
    border-radius: 0.3rem;
    position: absolute;
    right: -0.72rem;
    top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 0.85rem;
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(1.95rem + (0.17rem / 2));
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 100%;
    box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    height: 0.3rem;
    padding: 0.05rem 0.1rem;
    white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #216ba5;
    color: white;
    font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__week-number {
    color: #ccc;
    display: inline-block;
    text-align: center;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #000;
    display: inline-block;
    text-align: center;
}

.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__month--selected:hover,
.react-datepicker__month--in-selecting-range:hover,
.react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
    color: #ccc;
    pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
    cursor: default;
    background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text {
    cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today {
    font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted {
    border-radius: 0.3rem;
    background-color: #3dcc4a;
    color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover {
    background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1 {
    color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2 {
    color: green;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
    border-radius: 0.3rem;
    background-color: #2a87d0;
    color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover {
    background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range {
    background-color: rgba(33,107,165,0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range,
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range,
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range {
    background-color: #f0f0f0;
    color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled {
    cursor: default;
    color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover {
    background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__month-text.react-datepicker__month--in-range:hover,
.react-datepicker__month-text.react-datepicker__quarter--selected:hover,
.react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
    background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: #f0f0f0;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
    border: 0.01rem solid transparent;
    border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
    cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
    border-top-color: #ccc;
    float: right;
    margin-left: 0.2rem;
    top: 0.08rem;
    position: relative;
    border-width: 0.1rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
    background-color: #f0f0f0;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 0.3rem;
    z-index: 1;
    text-align: center;
    border-radius: 0.3rem;
    border: 0.01rem solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
    cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
    height: 1.5rem;
    overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
    line-height: 0.2rem;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
    background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
    position: absolute;
    left: 0.15rem;
}

.react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 0.06rem 0 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    background-color: #216ba5;
    color: #fff;
    border-radius: 50%;
    height: 0.16rem;
    width: 0.16rem;
    padding: 0.02rem;
    font-size: 0.12rem;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "\00d7";
}

.react-datepicker__today-button {
    background: #f0f0f0;
    border-top: 0.01rem solid #aeaeae;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 0.05rem 0;
    clear: left;
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2;
}

.react-datepicker__portal .react-datepicker__navigation {
    border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #e6e6e6;
    cursor: default;
}

.react-datepicker__close-icon {
    width: 0.4rem;
    height: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-datepicker__close-icon::after {
    cursor: pointer;
    color: #ffffff;
    background: rgba(0,0,0,0.5);
    height: 0.24rem;
    width: 0.24rem;
    line-height: 0.24rem;
    content: "x";
    display: flex;
    justify-content: center;
    align-items: center;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
    border-radius: 0rem;
    background-color: #981519;
    color: #ffffff;
}

.datetime-picker .calendar table thead th {
    color: #4a3917;
}

.datetime-picker .calendar table tbody tr td {
    border-radius: 0;
}

.datetime-picker .calendar table tbody tr td.now {
    color: #981519;
}

.datetime-picker .calendar table tbody tr td.selected {
    background-color: #981519;
    color: #ffffff;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    display: inline-block;
    text-align: center;
    width: 0.25rem;
    line-height: 0.25rem;
    margin: 0.075rem;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
    background-color: rgba(152,21,25,0.5);
    border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
    border-radius: 0;
    background-color: #981519;
    color: #ffffff;
}

.calendar-position {
    right: 0;
    top: 0;
    margin-right: 0.05rem;
}
/*datetime React end*/


/*Medai Css*/
@media (min-width: 2000px) {
    .home-container .banner .slider .slider-container img {
       height: 100% !important;
   }
}

@media (min-width: 1439px) {
    html {
        font-size: 90PX;
    }

    html *[data-screen="mobile"],
    html *[data-screen="pad"] {
       display: none;
    }

    html .home-container .banner .slider .slider-container img {
       width: 100% !important;
    }
}

@media (min-width: 1024px) and (max-width: 1439px) {
    html {
       font-size: calc(100vw / 14.8);
    }

    html *[data-screen="mobile"],
    html *[data-screen="pad"] {
       display: none;
    }

   html .bg-content-section .home-container .banner {
       height: 4rem !important;
   }

   html .home-container .banner .slider .slider-container img {
       width: 100% !important;
   }
}

@media (max-width: 1024px) {
    html {
        font-size: 9.25926vw;
        color: #6d6d6d;
        background: #ffffff;
    }

    html *[data-screen="web"],
    html *[data-screen="mobile"] {
        display: none;
    }

    #root #ui-desktop .bg-content-section .side-popup,
    #root #ui-desktop .bg-content-section .dota2-box {
        display: none;
    }
 
    #root #ui-desktop .bg-content-section .common-title,
    #root #ui-desktop .bg-content-section .my-collection .title-box .item-title,
    #root #ui-desktop .my-collection .title-box .bg-content-section .item-title,
    #root #ui-desktop .bg-content-section .dropping-hot .item-title,
    #root #ui-desktop .dropping-hot .bg-content-section .item-title,
    #root #ui-desktop .bg-content-section .donot-miss .item-title,
    #root #ui-desktop .donot-miss .bg-content-section .item-title {
        font-size: 0.35rem;
        line-height: 100%;
    }

    #root #ui-desktop .bg-content-section .banner{
        height: 5.56rem;
    }

    #root #ui-desktop .bg-content-section .banner .slider .slider-container>div>div div[data-screen="web"] {
        display: none;
    }
 
    #root #ui-desktop .bg-content-section .banner .slider .slider-container>div>div img {
        height: 100%;
    }
 
    #root #ui-desktop .bg-content-section .banner .slider .slider-container>div>div img[data-screen="web"] {
        display: none;
    }
 
    #root #ui-desktop .bg-content-section .banner .slider .slider-container>div>div img[data-screen="pad"] {
        display: block;
    }
 
    #root #ui-desktop .bg-content-section .banner .slider .pagination li:not(:last-child) {
        margin-right: 0.12rem;
    }
     
    #root #ui-desktop .bg-content-section .sports {
        height: 10.4rem;
        width: 100%;
        background: #0f181e url(../../static/html/index/section.png) no-repeat center -2.45rem;
        background-size: 100% 12.32rem;
    }

    #root #ui-desktop .bg-content-section .sports .item-title {
        font-size: 0.35rem;
        line-height: 100%;
    }

    #root #ui-desktop .bg-content-section .sports .contact-box {
        display: none;
    }

    #root #ui-desktop .bg-content-section .sports .step-box {
        height: 2.03rem;
        width: 20.48rem;
        background: transparent url(../../static/html/menu/step.png) no-repeat center center;
        background-size: 20.48rem 2.03rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box .container {
        padding-left: 2.8rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box ul li:nth-child(1) {
        width: 6.82rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box ul li:nth-child(2) {
        width: 5.92rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box ul li>i {
        width: 1.05rem;
        height: 1.05rem;
        margin-top: 0.64rem;
        margin-right: 0.24rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box ul li>div>span:nth-child(1) {
        font-size: 0.27rem;
        line-height: 0.8rem;
    }

    #root #ui-desktop .bg-content-section .sports .step-box ul li>div>span:nth-child(2) {
        font-size: 0.36rem;
        line-height: 0.36rem;
    }

    #root #ui-desktop .bg-content-section .sports .group {
        flex-direction: column;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games {
        height: 8.3rem;
        width: 100%;
        padding-left: 0.93rem;
        background-position: 12.52rem 1.3rem;
        background-size: 6.88rem 8.11rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .content-item .item-content {
        position: absolute;
        padding-left: 14.6rem;
        padding-top: 2.7rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .content-item .item-content.provider-CMD {
        background: url(../../static/html/index/1.png) no-repeat 3.5rem bottom;
        background-size: 11.43rem 6.76rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .content-item .item-content.provider-IBC {
        background: url(../../static/html/index/2.png) no-repeat 1.2rem 1rem;
        background-size: 13.97rem 15.19rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .content-item .item-content.provider-UNITEDGAMING {
        background: url(../../static/html/index/4.png) no-repeat 4rem 1rem;
        background-size: 12.82rem 13.72rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .content-item .item-content.provider-BR {
        background: url(../../static/html/index/3.png) no-repeat 2.5rem 1rem;
        background-size: 13.97rem 15.19rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .item-title {
        margin-top: 0.8rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .slider-container>div .item-content {
        position: absolute;
        padding-left: 14.6rem;
        padding-top: 2.7rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .slider-container>div .item-content .icon-box {
        height: 1.2rem;
        display: flex;
        align-items: center;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .slider-container>div .item-content p {
        font-size: 0.3rem;
        line-height: 0.34rem;
        padding: 0.46rem 0;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .slider-container>div .item-content .btn {
        width: 2.93rem;
        height: 0.54rem;
        font-size: 0.31rem;
        border-radius: 0.09rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination {
        left: 0.93rem;
        top: 2.08rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li {
        width: 3.42rem;
        height: 0.91rem;
        border-radius: 0.11rem;
        background: #1e2022;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li:not(:last-child) {
        margin-bottom: 0.73rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div {
        padding-left: 0.39rem;
        display: flex;
        align-items: flex-start;
        padding-top: 0.25rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div::before {
        height: 0.06rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div::after {
        height: 0.1rem;
        bottom: 0.04rem;
        width: 1.6rem;
        left: 0.32rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div.UNITEDGAMING {
        line-height: 0.35rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div span {
        font-size: 0.3rem;
        width: 1.4rem;
        line-height: 0.32rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li>div span.BTI {
        line-height: 0.35rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li .provider-character.provider-CMD {
        right: -0.05rem;
        top: -0.45rem;
        width: 1.55rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li .provider-character.provider-IBC {
        right: -0.06rem;
        top: -0.5rem;
        width: 1.25rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li .provider-character.provider-UNITEDGAMING {
        right: 0;
        top: -0.55rem;
        width: 1.4rem;
    }

    #root #ui-desktop .bg-content-section .sports .group .sports-games .slider .pagination li .provider-character.provider-BR {
        right: -0.94rem;
        top: -0.55rem;
        width: 2.55rem;
    }
    #root #ui-desktop .up-events {
        display:none;
    }
    
    
    #root #ui-desktop #footer .top-footer::before {
        height: 0.02rem;
        top: 7.1rem;
    }

    #root #ui-desktop #footer .top-footer-box {
        padding-top: 0.85rem;
        padding-left: 0.885rem;
        padding-right: 0.84rem;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider {
        position: relative;
        display: flex;
        flex-direction: row;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider .mergeLayout {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider .mergeLayout.followUs {
        width: 100%;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li {
        line-height: 0.45rem;
        font-size: 0.21rem;
        margin: 0 0 0 0.rem;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li:last-child {
        margin-right: 0;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li div[class*="-ftrmerge"] {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li div[class*="-box"].followUs-box i {
        margin-right: 0.2rem;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li div[class*="-box"].download-box {
        width: 1.08rem;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li div[class*="-box"].download-box svg {
        margin-right: 0.2rem;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li .title {
            height: 0.44rem;
            white-space: nowrap;
        }

    #root #ui-desktop #footer .top-footer-box .top .provider li i,
    #root #ui-desktop #footer .top-footer-box .top .provider li svg {
        margin: 0 0.34rem 0.34rem 0;
    }

    #root #ui-desktop #footer .top-footer-box .top .provider li:nth-child(6) .title {
        margin-bottom: 0.06rem;
    }

    #root #ui-desktop #footer .top-footer-box .copy-right-box {
        padding: 0.4rem 0 0 0;
    }

    #root #ui-desktop #footer .top-footer-box .copy-right-box .copy-right {
        font-size: 0.18rem;
        white-space: nowrap;
        justify-content: unset;
    }

    #root #ui-desktop #footer .top-footer-box .copy-right-box .current-lan {
        font-size: 0.21rem;
        margin-top: 0.24rem;
    }

    #root #ui-desktop #footer .top-footer-box .copy-right-box .current-lan>i {
        width: 0.44rem;
        height: 0.44rem;
    }

    #root #ui-desktop #footer .top-footer-box .bottom {
        height: 3.39rem;
    }

    #root #ui-desktop #footer .top-footer-box .lan-list {
        font-size: 0.21rem;
        margin-top: 0.7rem;
    }

    #root #ui-desktop #footer .top-footer-box .lan-list li {
        margin-right: 0.32rem;
    }

   .icon-logo {
       width: 2.44rem;
       height: 0.62rem;
   }

   .icon-arrow {
       width: 0.22rem;
       height: 0.1rem;
   }

   .icon-refesh {
       width: 0.31rem;
       height: 0.31rem;
   }

   .icon-pagcor {
       height: 0.42rem;
       width: 1.88rem;
   }

   .icon-godaddy {
       height: 0.39rem;
       width: 2.03rem;
   }

   .icon-iovation {
       width: 1.79rem;
       height: 0.36rem;
   }

   .icon-global {
       width: 0.63rem;
       height: 0.63rem;
   }

   .icon-itechlab {
       width: 0.63rem;
       height: 0.6rem;
   }

   .icon-payouts {
       width: 1.25rem;
       height: 0.44rem;
   }

   .icon-bmm {
       width: 0.82rem;
       height: 0.25rem;
   }

   .icon-bank-icon-ftr {
       width: 0.55rem;
       height: 0.5rem;
   }

   .icon-ethereum {
       width: 0.52rem;
       height: 0.52rem;
   }

   .icon-mobifone {
       width: 1.65rem;
       height: 0.37rem;
   }

   .icon-momo {
       width: 0.48rem;
       height: 0.48rem;
   }

   .icon-viettel {
       width: 0.82rem;
       height: 0.48rem;
   }

   .icon-viettelpay {
       width: 0.6rem;
       height: 0.56rem;
   }

   .icon-vinaphone {
       width: 1.92rem;
       height: 0.45rem;
   }

   .icon-zalo {
       width: 0.5rem;
       height: 0.47rem;
   }

   .icon-eeziepay {
       width: 1.16rem;
       height: 0.48rem;
   }

   .icon-paytrust {
       width: 1.35rem;
       height: 0.29rem;
   }

   .icon-help2pay {
       width: 0.56rem;
       height: 0.39rem;
   }

   .icon-curacao {
       width: 1.25rem;
       height: 0.48rem;
   }

   .icon-bitcoin {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-tng {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-usdt {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-facebook {
       width: 0.44rem;
       height: 0.44rem;
   }
   
   .icon-android-footer,
   .icon-ios-footer {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-youtube {
       width: 0.45rem;
       height: 0.44rem;
   }

   .icon-18 {
       width: 0.41rem;
       height: 0.38rem;
   }

   .icon-logo-footer {
       width: 1.49rem;
       height: 0.38rem;
   }

   .icon-cmd368 {
       width: 2.49rem;
       height: 0.48rem;
   }

   .icon-bti-sports {
       width: 2.5rem;
       height: 1rem;
   }

   .icon-ibc {
       width: 3.16rem;
       height: 0.76rem;
   }

   .icon-betradar {
       width: 3.21rem;
       height: 0.44rem;
   }

   .icon-love-off, 
   .icon-love-on {
       width: 0.39rem;
       height: 0.34rem;
   }

   .icon-close3 {
       width: 0.51rem;
       height: 0.51rem;
   }

   .icon-logo2 {
       width: 2.76rem;
       height: 0.7rem;
   }

   .icon-ae_sexy {
       width: 4.1rem;
       height: 1.56rem;
   }

   .icon-wm {
       width: 4.1rem;
       height: 1.56rem;
   }

   .icon-ag {
       width: 3.66rem;
       height: 1.22rem;
   }

   .icon-allbet {
       width: 3.31rem;
       height: 2.12rem;
   }

   .icon-dream_gaming {
       width: 2.94rem;
       height: 1.79rem;
   }

   .icon-ebet {
       width: 3.31rem;
       height: 1.02rem;
   }

   .icon-evolution {
       width: 3.56rem;
       height: 1.18rem;
   }

   .icon-gameplay {
       width: 4.22rem;
       height: 0.82rem;
   }

   .icon-gd {
       width: 3.37rem;
       height: 1.91rem;
   }

   .icon-ppl {
       width: 4.23rem;
       height: 1.93rem;
   }

   .icon-mg {
       width: 4.5rem;
       height: 1.15rem;
   }

   .icon-playtech {
       width: 3.98rem;
       height: 0.81rem;
   }

   .icon-sa_gaming {
       width: 3.88rem;
       height: 1.36rem;
   }

   .icon-bti {
       width: 3.35rem;
       height: 1.5rem;
   }

   .icon-ibc {
       width: 5rem;
       height: 1.17rem;
   }

   .icon-br {
       width: 4.8rem;
       height: 1.12rem;
   }

   .icon-cmd {
       width: 4.5rem;
       height: 1.18rem;
   }

   .icon-ime {
       width: 5.2rem;
       height: 1.08rem;
   }

   .icon-sgfw {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sgah {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sgzp {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sg {
       width: 5.2rem;
       height: 3.5rem;
   }

   .icon-gg {
       width: 5.2rem;
       height: 3.5rem;
   }

   .icon-gpthai {
       width: 3.35rem;
       height: 2.43rem;
   }

   .icon-gpsode {
       width: 3.92rem;
       height: 1.5rem;
   }

   .icon-qqk {
       width: 4.15rem;
       height: 0.85rem;
   }

   .icon-qqkthai {
       width: 3.4rem;
       height: 0.77rem;
   }

   .icon-gpkeno {
       width: 2.95rem;
       height: 0.84rem;
   }

   .icon-GP {
       width: 2rem;
       height: 0.53rem;
       margin-top: 0.2rem;
   }

   .icon-QQK {
       width: 1.9rem;
       height: 0.44rem;
       margin-top: 0.35rem;
   }

   .icon-poker2 {
       width: 4.5rem;
       height: 0.72rem;
   }

   .icon-android {
       width: 0.23rem;
       height: 0.26rem;
   }

   .icon-ios {
       width: 0.22rem;
       height: 0.26rem;
   }

   .icon-search {
       width: 0.24rem;
       height: 0.26rem;
   }

   .icon-level {
       width: 0.65rem;
       height: 0.4rem;
   }

   .icon-reload {
       width: 0.26rem;
       height: 0.26rem;
   }

   .icon-transfer {
       width: 0.87rem;
       height: 0.41rem;
   }

   #root #ui-desktop {
       padding-top: 1.91rem;
   }

   #root #ui-desktop .msg-enter,
   #root #ui-desktop .msg-exit {
       transform: translateX(100vw) translateZ(0);
   }

   #root #ui-desktop .msg-enter-active,
   #root #ui-desktop .msg-exit-active {
       transform: translateX(-100%) translateZ(0);
       transition: transform 20s linear;
   }

   #root #ui-desktop .notice {
       height: 0.55rem;
       font-size: 0.28rem;
   }

   #root #ui-desktop .home-notice-box .notice-popup {
       transform: scale(2);
   }

   #root #ui-desktop .announcement-box .announcement-popup {
       transform: scale(2);
   }

   #root #ui-desktop .announcement-box .announcement-img-popup .icon-close2 {
       top: -0.2rem;
       right: -0.2rem;
   }

   #root #ui-desktop .announcement-box .announcement-img-popup img {
       max-width: 12rem;
       max-height: 12rem;
   }

   #root #ui-desktop .login-page-box,
   #root #ui-desktop .reset-page-box {
       transform: scale(1.5);
   }


   #root #ui-desktop .header-section.info-page #header .top-header .top-header-box {
       padding-left: 0;
   }

   #root #ui-desktop .header-section.info-page #header .second-header span {
       font-size: 0.35rem;
   }

   #root #ui-desktop .header-section #header .top-header {
       height: 1.33rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box {
       padding-left: 0.845rem;
       width: 17.26rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .icon-logo {
       margin-top: 0.38rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list {
       max-width: 16rem;
       overflow-x: scroll;
       margin-left: 0.81rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list::-webkit-scrollbar {
       display: block;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list::-webkit-scrollbar-thumb {
       box-shadow: inset 0 0 0.06rem #787e8b;
       background: #787e8b;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li {
       font-size: 0.28rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li:not(:last-child) {
       margin-right: 0.28rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li img,
   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li svg {
       width: 0.43rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li img.new-icon,
   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li svg.new-icon {
       height: 0.7rem;
   }

   #root #ui-desktop .header-section #header .second-header {
       height: 0.58rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box {
       padding-left: 0.84rem;
       padding-right: 0.86rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .date-time {
       font-size: 0.2rem;
       padding-right: 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .date-time::before {
       height: 0.47rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box {
       font-size: 0.23rem;
       padding-left: 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .icon-flag {
       width: 0.39rem;
       height: 0.39rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .current-lan-box {
       margin-left: 0.12rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .current-lan-box svg {
       width: 0.22rem;
       height: 0.1rem;
       margin-left: 0.23rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .lan-list-box {
       font-size: 0.27rem;
       padding: 0.12rem 0.2rem;
       border-bottom-left-radius: 0.09rem;
       border-bottom-right-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .lan-list-box li span {
       margin-left: 0.2rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right.logined .down-list {
       display: none;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right .down-list li {
       font-size: 0.23rem;
       padding: 0 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right .down-list li:not(:last-child)::before {
       height: 0.47rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box {
       margin-left: 0.28rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn {
       width: 1.7rem;
       height: 0.49rem;
       font-size: 0.26rem;
       border-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn:not(:last-child) {
       margin-right: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn.btn-login {
       color: #e9e9e9;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login {
       padding-right: 0;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box {
       width: 5.61rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .currency {
       width: 2.83rem;
       font-size: 0.24rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .currency .reload-box {
       width: 0.31rem;
       height: 0.31rem;
       margin-left: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info {
       width: 2.78rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info::before {
       height: 0.42rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info>span {
       font-size: 0.24rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face {
       margin-left: 0.12rem;
       margin-right: 0.43rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face img {
       width: 0.39rem;
       height: 0.39rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face span {
       width: 0.24rem;
       height: 0.24rem;
       font-size: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .drop-list {
       width: 2.78rem;
       height: 2.93rem;
       border-bottom-left-radius: 0.09rem;
       border-bottom-right-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .drop-list li {
       font-size: 0.27rem;
       height: 0.5rem;
   }

   #root #ui-desktop .my-collection {
       height: 5.8rem;
   }

   #root #ui-desktop .my-collection .title-box .item-title {
       padding-top: 1.04rem;
       padding-bottom: 0.55rem;
   }

   #root #ui-desktop .dropping-hot {
       height: 9.74rem;
       padding: 0 0.86rem;
   }

   #root #ui-desktop .dropping-hot .item-title {
       padding-top: 1.04rem;
       padding-bottom: 0.55rem;
       line-height: 100%;
       padding-left: 0;
       font-size: 0.35rem;
   }

   #root #ui-desktop .dropping-hot .group .slider-box {
       display: none;
   }

   #root #ui-desktop .dropping-hot .group .games-list {
       margin-left: 0;
       width: 18.28rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs {
       height: 0.76rem;
       border-bottom: 0;
       margin-top: 0;
       margin-bottom: 0.34rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs::before {
       content: "";
       width: 18.27rem;
       height: 0.02rem;
       position: absolute;
       bottom: 0;
       left: -0.05rem;
       background: rgba(255,255,255,0.25);
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs li {
       border-radius: 0.09rem;
       border-bottom-left-radius: 0;
       border-bottom-right-radius: 0;
       padding: 0 0.43rem;
       height: 0.72rem;
       font-size: 0.3rem;
       border: 1PX solid rgba(255,255,255,0.25);
       border-bottom-color: transparent;
       margin-right: 0.2rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs li.on,
 #root #ui-desktop .dropping-hot .group .games-list .nav-tabs li:hover {
       border-width: 1PX;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs .more-games {
       right: 0.06rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs .more-games>span:nth-child(1) {
       font-size: 0.3rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .nav-tabs .more-games>span:nth-child(2) {
       font-size: 0.3rem;
   }

   #root #ui-desktop .dropping-hot .group .games-list .no-data {
       font-size: 0.35rem;
       line-height: 100%;
   }

   #root #ui-desktop .donot-miss {
       padding: 0 0.86rem;
       height: 6.34rem;
   }

   #root #ui-desktop .donot-miss .item-title {
       padding-top: 1.04rem;
       padding-bottom: 0.55rem;
       line-height: 100%;
       padding-left: 0;
       font-size: 0.35rem;
   }

   #root #ui-desktop .donot-miss .more-games {
       display: block;
       position: absolute;
       right: 0.83rem;
       top: 0.94rem;
       font-size: 0.3rem;
       color: #787e8b;
       cursor: pointer;
   }

   #root #ui-desktop .donot-miss .more-games:hover>span:nth-child(1) {
       text-decoration: underline;
   }

   #root #ui-desktop .donot-miss .donot-miss-list {
       overflow-x: scroll;
   }

   #root #ui-desktop .donot-miss .donot-miss-list::-webkit-scrollbar {
       display: none;
   }

   #root #ui-desktop .donot-miss .donot-miss-list::-webkit-scrollbar-thumb {
       box-shadow: inset 0 0 0.06rem #787e8b;
       background: #787e8b;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li {
       flex: 1 0 auto;
       width: 7.08rem;
       height: 3.5rem;
       margin-bottom: 0.1rem;
       padding-left: 3.3rem;
       align-items: flex-start;
       cursor: pointer;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .hover-box {
       display: none;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .title {
       font-size: 0.32rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .content {
       text-align: left;
       font-size: 0.28rem;
       line-height: 0.3rem;
       width: 3.5rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .content br {
       display: none;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(1) {
       background-image: url(../../static/html/index/1_pad.png);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(1) .content {
       width: 3.35rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(2) {
       padding-left: 3.8rem;
       background-image: url(../../static/html/index/2_pad.png);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(2) .content {
       width: 2.78rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(2) {
       padding-left: 3.8rem;
       background-image: url(../../static/html/index/2_pad.png);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(2) .content {
       width: 2.78rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(3) {
       background-image: url(../../static/html/index/3_pad.png);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:nth-child(4) {
       background-image: url(../../static/html/index/4_pad.png);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:not(:last-child) {
       margin-right: 0.37rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .bottom {
       height: 0.53rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .bottom::before {
       height: 0.47rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li .bottom::after {
       height: 0.08rem;
       bottom: 0.44rem;
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:hover .hover-box {
       transform: rotateX(90deg);
   }

   #root #ui-desktop .donot-miss .donot-miss-list li:hover .bottom {
       transform: translateY(0.4rem);
   }

   #root #ui-desktop .sports-panel {
        height: 9.4rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div {
        width: 100%;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div {
        width: 100%;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div img {
        height: 8.8rem;
        width: auto;
        margin: 0 auto;
        display: block;
        margin-left: -3rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div .container {
        height: 7rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div .container .content {
        width: 6.8rem;
        top: 1.5rem;
        font-size: 0.33rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div .container .content .icon-box {
        width: 5.5rem;
        height: 1.8rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div .container .content p {
        font-size: 0.35rem;
        line-height: 0.42rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div>div .container .content .btn {
        width: 2.27rem;
        height: 0.57rem;
        font-size: 0.28rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .BTI .suspend:nth-of-type(1) {
        left: 1.8rem;
        bottom: 3.7rem;
        width: 2rem;
        height: 1.5rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .BTI .suspend:nth-of-type(2) {
        left: 11.7rem;
        bottom: 4.9rem;
        width: 1.8rem;
        height: 1.4rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .CMD .suspend:nth-of-type(1) {
        left: 0.1rem;
        bottom: 0.3rem;
        width: 2.1rem;
        height: 1.5rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .CMD .suspend:nth-of-type(2) {
        left: 10.8rem;
        bottom: 5.5rem;
        width: 0.8rem;
        height: 1rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .CMD .suspend:nth-of-type(3) {
        left: 13rem;
        bottom: 5.5rem;
        width: 2.1rem;
        height: 1.48rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .IBC .suspend:nth-of-type(1) {
        left: 6.2rem;
        width: 1.1rem;
        height: 1rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .IBC .suspend:nth-of-type(2) {
        left: 10.5rem;
        bottom: 3.8rem;
        width: 1rem;
        height: 1.1rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .IBC .suspend:nth-of-type(3) {
        left: 7.3rem;
        bottom: 3.4rem;
        width: 1.3rem;
        height: 1.3rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .BR .suspend:nth-of-type(1) {
        left: 1.8rem;
        bottom: 5.3rem;
        width: 1rem;
        height: 1rem;
    }

    #root #ui-desktop .sports-panel .slider .slider-container>div .container .BR .suspend:nth-of-type(2) {
        left: 9.6rem;
        bottom: 4.7rem;
        width: 1.5rem;
        height: 1.3rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination {
        width: 17.1rem;
        bottom: 0.84rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination li {
        width: 2.6rem;
        height: 1.16rem;
        margin-bottom: 0.3rem;
        border-radius: 0.14rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination li:not(:last-child) {
        margin-right: 0.3rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination li:nth-child(6n),
    #root #ui-desktop .sports-panel .slider .pagination li:last-child {
        margin-right: 0;
    }

    #root #ui-desktop .sports-panel .slider .pagination li::before {
        height: 0.04rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination li::after {
        height: 0.08rem;
        bottom: 0.02rem;
        left: 0.66rem;
        width: 1.22rem;
    }

    #root #ui-desktop .sports-panel .slider .pagination li>div>i {
        transform: translate(-50%, -50%) scale(1.46);
    }

    #root #ui-desktop .hot-provider-casino {
        display: inline-block;
        background-image: url("../../static/html/casino/hoticon.png");
        background-size: 100% 100%;
        width: 0.71rem;
        height: 0.69rem;
        transform: translate(130%, -25%);
    }
  
    .react-datepicker__tab-loop {
        position: absolute;
        top: 0;
    }

    .react-datepicker {
        font-size: 0.5rem;
    }

    .react-datepicker__navigation {
        height: 0.2rem;
        width: 0.2rem;
        border: 0.2rem solid transparent;
    }

    .react-datepicker__navigation--previous {
        left: 0.2rem;
        border-right-color: #ccc;
    }

    .react-datepicker__navigation--previous:hover {
        border-right-color: #b3b3b3;
    }

    .react-datepicker__navigation--previous--disabled,
  .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: #e6e6e6;
        cursor: default;
    }

    .react-datepicker__navigation--next {
        right: 0.2rem;
        border-left-color: #ccc;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 0.8rem;
    }

    .react-datepicker__navigation--next:hover {
        border-left-color: #b3b3b3;
    }

    .react-datepicker__navigation--next--disabled,
  .react-datepicker__navigation--next--disabled:hover {
        border-left-color: #e6e6e6;
        cursor: default;
    }

    .react-datepicker__navigation--years {
        position: relative;
        top: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .react-datepicker__navigation--years-previous {
        top: 0.04rem;
        border-top-color: #ccc;
    }

    .react-datepicker__navigation--years-previous:hover {
        border-top-color: #b3b3b3;
    }

    .react-datepicker__navigation--years-upcoming {
        top: -0.04rem;
        border-bottom-color: #ccc;
    }

    .react-datepicker__navigation--years-upcoming:hover {
        border-bottom-color: #b3b3b3;
    }

    .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow {
        border-width: 0.2rem;
    }

    .react-datepicker__year-dropdown,
  .react-datepicker__month-dropdown,
  .react-datepicker__month-year-dropdown {
        height: 8rem;
        overflow: auto;
    }

    .react-datepicker__year-option,
  .react-datepicker__month-option,
  .react-datepicker__month-year-option {
        line-height: 1.2rem;
    }

    .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
        width: 0.75rem;
        line-height: 0.75rem;
    }

    #root .promosDetail-popup .popup {
        max-width: 15rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-header {
        padding: 0.16rem 0.2rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-header .popup-header-right {
        top: 0.16rem;
        right: 0.2rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-header .popup-header-right svg {
        width: 0.45rem;
        height: 0.45rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-header .popup-request {
        font-size: 0.28rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-header .popup-title {
        font-size: 0.3rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body {
        font-size: 0.26rem;
        line-height: 0.52rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .box-top {
        padding-bottom: 0.35rem;
        margin-bottom: 0.4rem;
        border-bottom: 0.02rem solid #404040;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .box-top .promoImg {
        width: 6.81rem;
        height: 3.09rem;
        margin-right: 0.43rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .box-top .box-content .content-title {
        margin-bottom: 0.23rem;
        font-size: 0.3rem;
        line-height: 0.3rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .box-top .box-content .content-desc {
        width: 5.2rem;
        height: 1.5rem;
        margin-bottom: 0.27rem;
        line-height: 0.32rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .box-top .box-content .time-box {
        line-height: 0.26rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .btn-box {
        padding-bottom: 0.08rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body .btn-box span {
        width: 1.54rem;
        height: 0.39rem;
        font-size: 0.23rem;
        border-radius: 0.1rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body p strong.content-title {
        font-size: 0.34rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body table {
        margin-bottom: 0.4rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-body table p strong {
        font-size: 0.28rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-footer {
        padding: 0.3rem 1rem;
    }

    #root #ui-desktop .promosDetail-popup .popup .popup-footer .popup-footer-right .popup-btn {
        width: 2.7rem;
        height: 0.74rem;
        border-radius: 0.14rem;
        font-size: 0.3rem;
    }

    #root #ui-desktop .vip-panel .slider {
        height: 7.2rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div img {
        height: 7.2rem;
        width: auto;
        margin: 0 auto;
        display: block;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container {
        height: 7rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container .content {
        width: 7.4rem;
        right: 6.2rem;
        top: 1.2rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container .content .title {
        margin-bottom: 0.49rem;
        font-size: 0.33rem;
        line-height: 0.33rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container .content .desc {
        font-size: 0.25rem;
        line-height: 0.3rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container .content .desc>p:first-child {
        margin-bottom: 0.56rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div>div .container .content .desc>p:nth-child(2) {
        margin-bottom: 0.39rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div .container .vip-suspend .suspend:nth-of-type(1) {
        width: 1.19rem;
        height: 1.3rem;
        right: 4.3rem;
        bottom: 2.2rem;
    }

    #root #ui-desktop .vip-panel .slider .slider-container>div .container .vip-suspend .suspend:nth-of-type(2) {
        width: 1.8rem;
        height: 1.48rem;
        right: 5.5rem;
        bottom: 0.2rem;
    }

    #root #ui-desktop .vip-panel .vip-box {
        max-width: 19rem;
    }

    #root #ui-desktop .vip-panel .vip-status {
        margin: 0.65rem auto 0.8rem;
        max-width: 19rem;
    }

    #root #ui-desktop .vip-panel .vip-status .status-img {
        width: 8.5rem;
        left: -2rem;
        top: -3rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status {
        width: 13.6rem;
        margin-bottom: 0.8rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .current-status {
        width: 100%;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .current-status .status-title {
        font-size: 0.26rem;
        line-height: 0.26rem;
        margin-left: 0.18rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .current-status .progress-bar {
        height: 0.37rem;
        margin-top: 0.26rem;
        border-radius: 0.3rem;
        background: repeating-linear-gradient(35deg, #3b3f4c, #3b3f4c 0.15rem, #111 0.15rem, #111 0.23rem);
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .current-status .progress-bar>span::after {
        background: repeating-linear-gradient(35deg, #977c32, #977c32 0.15rem, #111 0.15rem, #111 0.23rem);
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .status-level>div {
        width: 2rem;
        margin-top: 0.49rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .status-level>div::before {
        top: -0.4rem;
        height: 0.28rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .status-level>div p {
        font-size: 0.26rem;
        line-height: 0.26rem;
        margin-bottom: 0.17rem;
    }

    #root #ui-desktop .vip-panel .vip-status .progress-status .status-level>div span {
        font-size: 0.25rem;
        line-height: 0.28rem;
    }

    #root #ui-desktop .vip-panel .vip-table {
        max-width: 16.8rem;
        margin-left: 2rem;
    }

    #root #ui-desktop .vip-panel .vip-table tr td {
        font-size: 0.26rem;
        line-height: 0.26rem;
        padding: 0.32rem 0;
    }

    #root #ui-desktop .vip-panel .vip-table tr td:first-child {
        font-size: 0.28rem;
        line-height: 0.28rem;
        padding-right: 0.7rem;
    }

    #root #ui-desktop .vip-panel .vip-table tr td:not(:first-child) {
        width: 2rem;
    }

    #root #ui-desktop .vip-panel .vip-table tr td img {
        max-width: 1.5rem;
    }

    #root #ui-desktop .vip-panel .vip-table tr td.content-title {
        font-size: 0.28rem;
        line-height: 0.32rem;
        padding-top: 0.42rem;
    }

    #root #ui-desktop .vip-panel .vip-table tr:last-child td {
        padding: 0.32rem 0 0.49rem;
    }

    #root #ui-desktop .vip-panel .vip-table .table-type td {
        padding-bottom: 0.34rem;
        font-size: 0.28rem;
        line-height: 0.28rem;
    }

    #root #ui-desktop .vip-panel .vip-table .table-type td:first-child {
        font-size: 0.35rem;
        line-height: 0.35rem;
        padding-top: 0.18rem;
    }

    #root #ui-desktop .vip-panel .vip-table .table-type td:not(:first-child) {
        padding-top: 0.22rem;
    }

    #root #ui-desktop .vip-panel .vip-btm {
        margin-top: 0.77rem;
        max-width: 17rem;
        margin-left: 2rem;
    }

    #root #ui-desktop .about-panel,
  #root #ui-desktop .faq-panel,
  #root #ui-desktop .responsible-panel,
  #root #ui-desktop .terms-panel {
        padding-top: 0.57rem;
        padding-left: 1.72rem;
        padding-right: 1.72rem;
    }

    #root #ui-desktop .about-panel .container,
  #root #ui-desktop .faq-panel .container,
  #root #ui-desktop .responsible-panel .container,
  #root #ui-desktop .terms-panel .container {
        padding: 0.6rem 0.57rem 1.3rem;
        border-top-left-radius: 0.14rem;
        border-top-right-radius: 0.14rem;
    }

    #root #ui-desktop .about-panel .container .panel-title,
  #root #ui-desktop .faq-panel .container .panel-title,
  #root #ui-desktop .responsible-panel .container .panel-title,
  #root #ui-desktop .terms-panel .container .panel-title {
        font-size: 0.5rem;
        padding-bottom: 0.26rem;
        padding-left: 0.37rem;
    }

    #root #ui-desktop .about-panel .container .panel-content,
  #root #ui-desktop .faq-panel .container .panel-content,
  #root #ui-desktop .responsible-panel .container .panel-content,
  #root #ui-desktop .terms-panel .container .panel-content {
    font-size: 0.2rem;
    margin-left: 0.29rem;
    margin-top: 0.29rem;
    }

    #root #ui-desktop .about-panel .container .panel-content>*,
  #root #ui-desktop .faq-panel .container .panel-content>*,
  #root #ui-desktop .responsible-panel .container .panel-content>*,
  #root #ui-desktop .terms-panel .container .panel-content>* {
        margin-bottom: 0.42rem;
    }

    #root #ui-desktop .about-panel .container .panel-content .collapse,
  #root #ui-desktop .faq-panel .container .panel-content .collapse,
  #root #ui-desktop .responsible-panel .container .panel-content .collapse,
  #root #ui-desktop .terms-panel .container .panel-content .collapse {
        line-height: 1rem;
    }

    #root #ui-desktop .about-panel .container .panel-content .collapse .collapse-header,
  #root #ui-desktop .faq-panel .container .panel-content .collapse .collapse-header,
  #root #ui-desktop .responsible-panel .container .panel-content .collapse .collapse-header,
  #root #ui-desktop .terms-panel .container .panel-content .collapse .collapse-header {
        padding: 0 0.39rem;
    }

    #root #ui-desktop .about-panel .container .panel-content .collapse .collapse-item,
  #root #ui-desktop .faq-panel .container .panel-content .collapse .collapse-item,
  #root #ui-desktop .responsible-panel .container .panel-content .collapse .collapse-item,
  #root #ui-desktop .terms-panel .container .panel-content .collapse .collapse-item {
        padding: 0 0.39rem;
        line-height: 0.43rem;
    }

    #root #ui-desktop .about-panel .container .panel-content .collapse .collapse-item p,
  #root #ui-desktop .faq-panel .container .panel-content .collapse .collapse-item p,
  #root #ui-desktop .responsible-panel .container .panel-content .collapse .collapse-item p,
  #root #ui-desktop .terms-panel .container .panel-content .collapse .collapse-item p {
        margin-bottom: 0.35rem;
    }

    #root #ui-desktop .about-panel .container .panel-content .collapse.active .collapse-item,
  #root #ui-desktop .faq-panel .container .panel-content .collapse.active .collapse-item,
  #root #ui-desktop .responsible-panel .container .panel-content .collapse.active .collapse-item,
  #root #ui-desktop .terms-panel .container .panel-content .collapse.active .collapse-item {
        height: auto;
        padding: 0.45rem 0.39rem;
    }

    #root #ui-desktop .terms-panel .container .panel-content {
        margin-left: 0;
    }

    #root #ui-desktop .terms-panel .container .panel-content>* {
        margin-bottom: 0;
    }

    #root #ui-desktop .myaccount-referral-container,
    #root #ui-desktop .inbox-page,
    #root #ui-desktop .inbox-page .inbox-tab span,
    #root #ui-desktop .changepwd-page svg,
    #root #ui-desktop button {
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .changepwd-page svg {
          left: -0.2rem !important;
          bottom: -0.074rem !important;
      }
  
      #root #ui-desktop .member-panel {
          min-height: 15.53rem;
          background-position: -1.2rem -0.15rem;
          background-size: 23.43rem 9.28rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page {
          padding-left: 3.51rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav {
          min-height: 14.85rem;
          width: 2.58rem;
          border-top-left-radius: 0.11rem;
          border-top-right-radius: 0.11rem;
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info {
          padding-left: 0.28rem;
          padding-right: 0.26rem;
          padding-bottom: 0.37rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info .img-face {
          width: 0.92rem;
          height: 0.92rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info .username {
          font-size: 0.18rem;
          margin-top: 0.34rem;
          margin-bottom: 0.13rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info .level-box>div {
          font-size: 0.18rem;
          white-space: nowrap;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info .level-box>div>span:nth-child(1) {
          margin-bottom: 0.05rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .member-info::before {
          content: "";
          width: 2.03rem;
          height: 1PX;
          background: rgba(140,140,140,0.3);
          left: 0.28rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .nav-item>span {
          font-size: 0.2rem;
          margin-top: 0.49rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .nav-item li {
          height: 0.46rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-nav .nav-item:not(:last-child)::after {
          content: "";
          width: 2.03rem;
          height: 1PX;
          background: rgba(140,140,140,0.3);
          left: 0.28rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main {
          width: 10.98rem;
          min-height: 14.85rem;
          margin-left: 0.16rem;
          border-top-left-radius: 0.11rem;
          border-top-right-radius: 0.11rem;
          padding-bottom: 0.3rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet {
          padding-left: 0.49rem;
          padding-right: 0.29rem;
          padding-top: 0.31rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .transfer-group>i {
          margin-top: 0.13rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .group {
          display: flex;
          flex-direction: column;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .title {
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .reload .restore-box {
          margin-left: 0.32rem;
          padding: 0 0.2rem;
          height: 0.55rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .reload .restore-box .icon-restore {
          transform: scale(1.08);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .reload>span:nth-child(1) {
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-wallet .reload>span:nth-child(2) {
          font-size: 0.35rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .group-item {
          display: flex;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .group-item.input-item .title {
          display: flex;
          justify-content: space-between;
          transform: translateY(0.02rem);
          white-space: nowrap;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .group-item .title {
          width: 1.69rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .notice-box {
          margin-left: 1.69rem;
          padding-top: 0;
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .deposit-options-list {
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .bank-list {
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .amount-content {
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .amount-content .input-box {
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .currency-conversion {
          font-size: 0.14rem;
          color: #636870;
          margin: 1rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item.crypto-shareBox {
          margin-top: 0.68rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .date-content .react-datepicker__tab-loop {
          position: unset;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .deposit-page .group-item .date-content input.react-datepicker-time__input {
          width: 4.5rem;
          height: 1rem;
          border-radius: 0.2rem;
          font-size: 0.5rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .btn-submit {
          margin-left: 1.69rem;
          transform-origin: 0 0;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item {
          margin-bottom: 0.23rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .bank-list {
          transform-origin: 0 0;
          transform: scale(1.2);
          margin-bottom: 0.4rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box {
          width: 2.66rem;
          height: 0.3rem;
          display: flex;
          align-items: center;
          border-radius: 0.05rem;
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input {
          width: 100%;
          height: 100%;
          border-radius: 0.05rem;
          border: 1PX solid rgba(25,25,25,0.2);
          padding-left: 0.13rem;
          font-size: 0.16rem;
          transition: border 0.3s, background 0.3s;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input::-webkit-input-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input:-moz-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input::-moz-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input:-ms-input-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box input:focus {
          border: 1PX solid #ff4e00;
          background: #f3f2f2;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box.crypto-box,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box.amount-box {
          width: unset;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item .input-box.box-info {
          align-items: unset;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item:nth-child(2) {
          margin-bottom: 0.39rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .group-item.crypto-bank-option .bank-list {
          margin-bottom: unset;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .withdraw-page .notice-box {
          display: flex;
          flex-direction: column;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .changepwd-page .btn-submit {
          margin-left: 1.69rem;
          transform-origin: 0 0;
          transform: scale(1.2);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .changepwd-page .group-item {
          margin-bottom: 0.24rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .changepwd-page .group-item .input-box {
          transform-origin: 0 0;
          transform: scale(1.2);
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .changepwd-page .group-item .playerId-box {
          transform-origin: 0 0;
          transform: scale(1.2);
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .bank-page .bank-account-list {
          transform-origin: 0 0;
          transform: scale(1.125);
          padding-bottom: 0.6rem;
          width: 8.75rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .bank-page .bank-add {
          transform-origin: 0 0;
          transform: scale(1.125);
          width: 9rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .bank-page .bank-add .btn-submit {
          margin-left: 1.69rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .bank-page .bank-add .group-item .input-box {
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .bank-page .bank-add .group-item .group>span {
          height: 0.3rem;
          display: flex;
          align-items: center;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .inbox-page .inbox-list {
          transform-origin: 0 0;
          transform: scale(1.125);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .history-page .history-tools {
          transform-origin: 0 0;
          transform: scale(1.125);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .history-page .history-list {
          transform-origin: 0 0;
          transform: scale(1);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .profile-page .info-box {
          transform-origin: 0 0;
          transform: scale(1.125);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page {
          padding-top: 0.39rem;
          padding-left: 0.51rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box {
          width: 4.54rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group {
          margin-bottom: 0.26rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-absolute {
          right: 0;
          top: 0.5rem;
          transform: translateX(100%);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-absolute>i {
          margin: 0.09rem 0;
          transform: translateX(0.08rem);
          cursor: pointer;
          width: 0.19rem;
          height: 0.21rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-absolute::before {
          content: "";
          width: 0.28rem;
          height: 0.31rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-absolute::after {
          content: "";
          width: 0.28rem;
          height: 0.31rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-code {
          margin-bottom: 0.05rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-code .group>span {
          font-size: 0.14rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-amount {
          margin-bottom: 0.39rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box {
          width: 6.56rem;
          height: 3.87rem;
          border: 1PX solid #ff4e00;
          border-radius: 0.06rem;
          left: 0;
          top: 0.41rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select {
          padding-top: 0.21rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .type-list ul {
          padding: 0 0.23rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .type-list ul li .icon-box {
          width: 0.65rem;
          height: 0.65rem;
          margin-bottom: 0.04rem;
          transform: scale(1.14);
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .type-list ul li>span {
          font-size: 0.16rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list {
          width: 6.13rem;
          height: 2.28rem;
          border-radius: 0.07rem;
          margin-top: 0.35rem;
          padding: 0.17rem 0.14rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list ul {
          margin-bottom: 0.05rem;
          left: 0.14rem;
          top: 0.17rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list ul li {
          width: 1.4rem;
          height: 0.34rem;
          border: 1PX solid rgba(25,25,25,0.2);
          border-radius: 0.06rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list ul li:not(:nth-child(4n)) {
          margin-right: 0.09rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list ul li.on,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-transfer .group .transfer-select-box .transfer-select .item-list ul li:hover {
          border: 1PX solid #ff4e00;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group.group-btn {
          padding-left: 169x;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .title {
          width: 1.69rem;
          height: 0.34rem;
          line-height: 0.34rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box {
          width: 2.85rem;
          height: 0.34rem;
          margin-bottom: 0.06rem;
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box>.icon-box {
          right: 0.14rem;
          height: 100%;
          display: flex;
          align-items: center;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box .btn-all-in {
          width: 0.9rem;
          height: 0.34rem;
          border-radius: 0.06rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select {
          border-radius: 0.06rem;
          border: 1PX solid rgba(25,25,25,0.2);
          padding-left: 0.16rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input::-webkit-input-placeholder,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select::-webkit-input-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input:-moz-placeholder,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select:-moz-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input::-moz-placeholder,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select::-moz-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input:-ms-input-placeholder,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select:-ms-input-placeholder {
          transition: color 0.3s;
          color: #8f8f8f;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box input:focus,
    #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .group .input-box select:focus {
          border: 1PX solid #ff4e00;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .btn {
          width: 1.35rem;
          height: 0.34rem;
          border-radius: 0.06rem;
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .form-box .item-group .btn.btn-reset {
          margin-left: 0.17rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel {
          margin-left: 0.85rem;
          width: 4.59rem;
          min-height: 11.73rem;
          border-radius: 0.06rem;
          padding: 0.27rem 0.23rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-nav {
          padding: 1PX 0.07rem;
          border-bottom: 1PX solid #a2aabd;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-nav li {
          width: 1.89rem;
          height: 0.4rem;
          font-size: 0.18rem;
          border-top-left-radius: 0.08rem;
          border-top-right-radius: 0.08rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .total-box {
          width: 2.54rem;
          height: 0.34rem;
          border-radius: 0.05rem;
          padding: 0 0.21rem;
          padding-right: 0.11rem;
          margin-left: 1.43rem;
          margin-top: 0.22rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .total-box span {
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .total-box div span {
          font-size: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .total-box div .icon-box {
          transform: scale(0.7);
          margin-left: 0.18rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-content {
          padding: 0 0.09rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-content>div {
          margin-bottom: 0.25rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-content>div .title {
          padding: 0 0.08rem;
          font-size: 0.18rem;
          border-bottom: 1PX solid rgba(162,170,189,0.5);
          height: 0.37rem;
      }
  
      #root #ui-desktop .member-panel .member-com-page .member-main .member-page .transfer-page .turnover-panel .turnover-content>div ul li {
          padding: 0 0.08rem;
          font-size: 0.18rem;
          height: 0.37rem;
      }
}

@media (hover: none) {
   html {
    font-size: 9.25926vw;
    color: #6d6d6d;
    background: #ffffff;
   }

   html *[data-screen="web"],
   html *[data-screen="mobile"] {
       display: none;
   }

   .icon-logo {
       width: 2.44rem;
       height: 0.62rem;
   }

   .icon-arrow {
       width: 0.22rem;
       height: 0.1rem;
   }

   .icon-refesh {
       width: 0.31rem;
       height: 0.31rem;
   }

   .icon-pagcor {
       height: 0.42rem;
       width: 1.88rem;
   }

   .icon-godaddy {
       height: 0.39rem;
       width: 2.03rem;
   }

   .icon-iovation {
       width: 1.79rem;
       height: 0.36rem;
   }

   .icon-global {
       width: 0.63rem;
       height: 0.63rem;
   }

   .icon-itechlab {
       width: 0.63rem;
       height: 0.6rem;
   }

   .icon-payouts {
       width: 1.25rem;
       height: 0.44rem;
   }

   .icon-bmm {
       width: 0.82rem;
       height: 0.25rem;
   }

   .icon-bank-icon-ftr {
       width: 0.55rem;
       height: 0.5rem;
   }

   .icon-ethereum {
       width: 0.52rem;
       height: 0.52rem;
   }

   .icon-mobifone {
       width: 1.65rem;
       height: 0.37rem;
   }

   .icon-momo {
       width: 0.48rem;
       height: 0.48rem;
   }

   .icon-viettel {
       width: 0.82rem;
       height: 0.48rem;
   }

   .icon-viettelpay {
       width: 0.6rem;
       height: 0.56rem;
   }

   .icon-vinaphone {
       width: 1.92rem;
       height: 0.45rem;
   }

   .icon-zalo {
       width: 0.5rem;
       height: 0.47rem;
   }

   .icon-eeziepay {
       width: 1.16rem;
       height: 0.48rem;
   }

   .icon-paytrust {
       width: 1.35rem;
       height: 0.29rem;
   }

   .icon-help2pay {
       width: 0.56rem;
       height: 0.39rem;
   }

   .icon-curacao {
       width: 1.25rem;
       height: 0.48rem;
   }

   .icon-bitcoin {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-tng {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-usdt {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-facebook {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-android-footer,
   .icon-ios-footer {
       width: 0.44rem;
       height: 0.44rem;
   }

   .icon-youtube {
       width: 0.45rem;
       height: 0.44rem;
   }

   .icon-18 {
       width: 0.41rem;
       height: 0.38rem;
   }

   .icon-logo-footer {
       width: 1.49rem;
       height: 0.38rem;
   }

   .icon-cmd368 {
       width: 2.49rem;
       height: 0.48rem;
   }

   .icon-bti-sports {
       width: 2.5rem;
       height: 1rem;
   }

   .icon-ibc {
       width: 3.16rem;
       height: 0.76rem;
   }

   .icon-betradar {
       width: 3.21rem;
       height: 0.44rem;
   }

   .icon-love-off,
   .icon-love-on {
       width: 0.39rem;
       height: 0.34rem;
   }

   .icon-close3 {
       width: 0.51rem;
       height: 0.51rem;
   }

   .icon-logo2 {
       width: 2.76rem;
       height: 0.7rem;
   }

   .icon-ae_sexy {
       width: 4.1rem;
       height: 1.56rem;
   }

   .icon-wm {
       width: 4.1rem;
       height: 1.56rem;
   }

   .icon-ag {
       width: 3.66rem;
       height: 1.22rem;
   }

   .icon-allbet {
       width: 3.31rem;
       height: 2.12rem;
   }

   .icon-dream_gaming {
       width: 2.94rem;
       height: 1.79rem;
   }

   .icon-ebet {
       width: 3.31rem;
       height: 1.02rem;
   }

   .icon-evolution {
       width: 3.56rem;
       height: 1.18rem;
   }

   .icon-gameplay {
       width: 4.22rem;
       height: 0.82rem;
   }

   .icon-gd {
       width: 3.37rem;
       height: 1.91rem;
   }

   .icon-ppl {
       width: 4.23rem;
       height: 1.93rem;
   }

   .icon-mg {
       width: 4.5rem;
       height: 1.15rem;
   }

   .icon-playtech {
       width: 3.98rem;
       height: 0.81rem;
   }

   .icon-sa_gaming {
       width: 3.88rem;
       height: 1.36rem;
   }

   .icon-bti {
       width: 3.35rem;
       height: 1.5rem;
   }

   .icon-ibc {
       width: 5rem;
       height: 1.17rem;
   }

   .icon-br {
       width: 4.8rem;
       height: 1.12rem;
   }

   .icon-cmd {
       width: 4.5rem;
       height: 1.18rem;
   }

   .icon-ime {
       width: 5.2rem;
       height: 1.08rem;
   }

   .icon-sgfw {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sgah {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sgzp {
       width: 4.3rem;
       height: 2.53rem;
   }

   .icon-sg {
       width: 5.2rem;
       height: 3.5rem;
   }

   .icon-gg {
       width: 5.2rem;
       height: 3.5rem;
   }

   .icon-gpthai {
       width: 3.35rem;
       height: 2.43rem;
   }

   .icon-gpsode {
       width: 3.92rem;
       height: 1.5rem;
   }

   .icon-qqk {
       width: 4.15rem;
       height: 0.85rem;
   }

   .icon-qqkthai {
       width: 3.4rem;
       height: 0.77rem;
   }

   .icon-gpkeno {
       width: 2.95rem;
       height: 0.84rem;
   }

   .icon-GP {
       width: 2rem;
       height: 0.53rem;
       margin-top: 0.2rem;
   }

   .icon-QQK {
       width: 1.9rem;
       height: 0.44rem;
       margin-top: 0.35rem;
   }

   .icon-poker2 {
       width: 4.5rem;
       height: 0.72rem;
   }

   .icon-android {
       width: 0.23rem;
       height: 0.26rem;
   }

   .icon-ios {
       width: 0.22rem;
       height: 0.26rem;
   }

   .icon-search {
       width: 0.24rem;
       height: 0.26rem;
   }

   .icon-level {
       width: 0.65rem;
       height: 0.4rem;
   }

   .icon-reload {
       width: 0.26rem;
       height: 0.26rem;
   }

   .icon-transfer {
       width: 0.87rem;
       height: 0.41rem;
   }
   
   #root #ui-desktop .msg-enter,
   #root #ui-desktop .msg-exit {
       transform: translateX(100vw) translateZ(0);
   }

   #root #ui-desktop .msg-enter-active,
   #root #ui-desktop .msg-exit-active {
       transform: translateX(-100%) translateZ(0);
       transition: transform 20s linear;
   }

   #root #ui-desktop .notice {
       height: 0.55rem;
       font-size: 0.28rem;
   }

   #root #ui-desktop .home-notice-box .notice-popup {
       transform: scale(2);
   }

   #root #ui-desktop .announcement-box .announcement-popup {
       transform: scale(2);
   }

   #root #ui-desktop .announcement-box .announcement-img-popup .icon-close2 {
       top: -0.2rem;
       right: -0.2rem;
   }

   #root #ui-desktop .announcement-box .announcement-img-popup img {
       max-width: 12rem;
       max-height: 12rem;
   }

   #root #ui-desktop .login-page-box,
 #root #ui-desktop .reset-page-box {
       transform: scale(1.5);
   }

   #root #ui-desktop .game-content li {
       width: 3.48rem;
       height: 2.83rem;
   }

   #root #ui-desktop .game-content li:not(:nth-child(5n)) {
       margin-right: 0.22rem;
   }

   #root #ui-desktop .game-content li .img-box {
       width: 3.48rem;
       height: 2.11rem;
   }

   #root #ui-desktop .game-content li .img-box .hover-box>span {
       width: 1.59rem;
       height: 0.49rem;
       font-size: 0.25rem;
       border-right: 0.05rem;
   }

   #root #ui-desktop .game-content li>p {
       height: 0.72rem;
       font-size: 0.25rem;
       padding-left: 0.32rem;
       padding-right: 0.2rem;
       padding-bottom: 0;
   }

   #root #ui-desktop .game-content li>p::before {
       height: 0.07rem;
   }

   #root #ui-desktop .game-content li>p::after {
       height: 0.09rem;
       bottom: 0.04rem;
       width: 1.64rem;
       left: 0.34rem;
   }

   #root #ui-desktop .game-content li>p>span {
       width: 2.1rem;
   }

   #root #ui-desktop .header-section.info-page #header .top-header .top-header-box {
       padding-left: 0;
   }

   #root #ui-desktop .header-section.info-page #header .second-header span {
       font-size: 0.35rem;
   }

   #root #ui-desktop .header-section #header .top-header {
       height: 1.33rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box {
       padding-left: 0.845rem;
       width: 17.26rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .icon-logo {
       margin: auto 0 0.17rem 0;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list {
       max-width: 16rem;
       overflow-x: scroll;
       margin-left: 0.81rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list::-webkit-scrollbar {
       display: block;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list::-webkit-scrollbar-thumb {
       box-shadow: inset 0 0 0.06rem #787e8b;
       background: #787e8b;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li {
       font-size: 0.28rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li:not(:last-child) {
       margin-right: 0.28rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li img,
 #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li svg {
       width: 0.43rem;
   }

   #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li img.new-icon,
 #root #ui-desktop .header-section #header .top-header .top-header-box .nav-list li svg.new-icon {
       height: 0.7rem;
   }

   #root #ui-desktop .header-section #header .second-header {
       height: 0.58rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box {
       padding-left: 0.84rem;
       padding-right: 0.86rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .date-time {
       font-size: 0.2rem;
       padding-right: 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .date-time::before {
       height: 0.47rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box {
       font-size: 0.23rem;
       padding-left: 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .icon-flag {
       width: 0.39rem;
       height: 0.39rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .current-lan-box {
       margin-left: 0.12rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .current-lan-box svg {
       width: 0.22rem;
       height: 0.1rem;
       margin-left: 0.23rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .lan-list-box {
       font-size: 0.27rem;
       padding: 0.12rem 0.2rem;
       border-bottom-left-radius: 0.09rem;
       border-bottom-right-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .left .lan-select-box .lan-list-box li span {
       margin-left: 0.2rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right.logined .down-list {
       display: none;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right .down-list li {
       font-size: 0.23rem;
       padding: 0 0.27rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right .down-list li:not(:last-child)::before {
       height: 0.47rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box {
       margin-left: 0.28rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn {
       width: 1.7rem;
       height: 0.49rem;
       font-size: 0.26rem;
       border-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn:not(:last-child) {
       margin-right: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div .btn-box .btn.btn-login {
       color: #e9e9e9;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login {
       padding-right: 0;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box {
       width: 5.61rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .currency {
       width: 2.83rem;
       font-size: 0.24rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .currency .reload-box {
       width: 0.31rem;
       height: 0.31rem;
       margin-left: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info {
       width: 2.78rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info::before {
       height: 0.42rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info>span {
       font-size: 0.24rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face {
       margin-left: 0.12rem;
       margin-right: 0.43rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face img {
       width: 0.39rem;
       height: 0.39rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .face span {
       width: 0.24rem;
       height: 0.24rem;
       font-size: 0.17rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .drop-list {
       width: 2.78rem;
       height: 2.93rem;
       border-bottom-left-radius: 0.09rem;
       border-bottom-right-radius: 0.09rem;
   }

   #root #ui-desktop .header-section #header .second-header .second-header-box .right>div.after-login .account-info-box .account-info .drop-list li {
       font-size: 0.27rem;
       height: 0.5rem;
   }

   #root #ui-desktop .my-collection {
       height: 5.8rem;
   }

   #root #ui-desktop .my-collection .title-box .item-title {
       padding-top: 1.04rem;
       padding-bottom: 0.55rem;
   }

   #seo-footer-container #seo-footer {
       width: 100%;
       padding-left: 1.77rem;
       padding-right: 1.68rem;
   }

   #seo-footer-container #seo-footer>div .content-title {
       font-size: 0.21rem;
   }

   #seo-footer-container #seo-footer>div .content-text {
       font-size: 0.21rem;
   }
}